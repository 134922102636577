.message-card-container {
  height: 80px;
  border-bottom: 1px #e8e9ee solid;
  border-radius: 2px;

  .message-card {
    width: 98%;
    margin: 0px auto;
    color: @color-Manatee;
    font-size: 14.5px;

    .message-title {
      font-weight: 600;
      color: @color-Shark;
    }
    .message-msg {
      font-weight: 400;
      color: @color-Manatee;
    }
    .message-date {
      font-weight: 400;
    }
  }
}

.message-card-container:hover {
  background-color: #ebefff;

  .message-card {
    color: @color-Mariner;

    .message-title {
      color: @color-Mariner;
    }
    .message-msg {
      color: @color-Mariner;
    }
  }
}
