.button-lib {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  margin: 5px;
  font-weight: 600;
  letter-spacing: 0.75px;
  font-size: 1rem;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  @media (max-width: 600px) {
    font-size: 14px;
  }

  .button-lib-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.button-lib-icon {
  margin-right: 5px;
}

.button-type-2 {
  background-color: #fff1de;
  color: #feba01;
}

.button-type-1 {
  background-color: #0fa84f;
  color: #fff;
}
.button-type-1:hover {
  background-color: #0d9445;
}
.button-type-1:active {
  background-color: #29cea2;
}

.button-type-3 {
  background-color: #f7d8d8;
  color: #f26b6b;
}

.button-type-4 {
  background-color: transparent;
  color: #fff;
}
.button-type-5 {
  background-color: transparent;
  color: #505057;
}
.button-type-5:hover,
.button-type-5:active {
  color: rgb(63, 63, 70);
}

.button-type-6 {
  background-color: transparent;
  color: #40d2ac;
}

.button-type-6:hover {
  background-color: #40d2ac;
  color: #fff;
}

.button-type-7 {
  background-color: transparent;
  color: #bcbdc8;
  border: #bcbdc8 1px solid;
  font-weight: 400;
}

.button-type-7:hover,
.button-type-7:active {
  background-color: #e8ecff;
  color: #405cd2;
  border: #405cd2 1px solid;
}

.button-type-8 {
  background-color: transparent;
  color: #feba01;
  font-weight: 300;
}

.button-type-8:hover,
.button-type-8:active {
  background-color: #fff4e5;
  font-weight: 300;
}

.button-type-9 {
  background-color: transparent;
  color: #f26b6b;
}

.button-type-10 {
  background-color: transparent;
  color: #feba01;
}

.button-type-11 {
  background: #f4f4f6;
  color: #151b30;
}
.button-type-11:hover {
  background: #faf7f7;
  color: #151b30;
}

.button-type-12 {
  background-color: #f4f4f6;
  color: #405cd2;
}

.button-type-12:hover {
  background-color: #ececee;
}

.button-type-13 {
  background-color: #405cd2;
  color: #fff;
}
.button-type-13:hover {
  background-color: #3047a3;
}

.button-type-14 {
  background-color: transparent;
}
.button-type-14:hover {
  transform: scale(0.95);
}
.button-type-15 {
  color: #405cd2;
  background-color: transparent;
}

.button-type-16 {
  background-color: #f4f4f6;
  color: #000;
}

.button-type-16:hover {
  background-color: #ececee;
}
.button-type-17 {
  background-color: transparent;
  border-radius: 0;
}

.button-type-18 {
  background-color: #fff;
  border: solid #405cd2 2px;
  color: #405cd2;
}

.button-type-19 {
  background-color: #f26b6b;
  color: #fff;
}

.button-type-20 {
  background-color: #fff;
  border: solid #f26b6b 2px;
  color: #f26b6b;
}

.button-type-21 {
  background: #535353;
  color: #ffffff;
}


@primary-color: #405CD2;