.employe-profile-container {
  background-color: @color-White;
  border-radius: 10px;
  position: sticky;
  z-index: 40;
  top: 0;
  .employe-information-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
    @media (max-width: 1060px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .employe-img-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .employe-card {
        border-radius: 50%;
        height: 90px;
        width: 90px;
      }
      .edit-img {
        position: absolute;
        left: 2rem;
        top: 2.75rem;
        height: 80px;
        width: 80px;
      }
    }
    .employe-designation {
      color: #505057;
    }
  }

  .employe-border {
    width: 80%;
    margin: 0 auto;
    border-bottom: 1px solid #d5d6df;
  }
  .employe-details-container {
    padding: 1rem 2rem;
    .employe-info {
      margin: 5px 0;
      margin-top: 0.7rem;
      .employe-info-header {
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
      }
      .employe-info-data {
        font-size: 1rem;
        font-weight: 600;
        text-align: left;
        color: #232326;
      }
    }
  }
}
.personal-info-container {
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 1rem;
  .employe-profileinfo-container {
    background-color: @color-White;
    border-radius: 10px;

    .personal-information {
      width: 100%;
      padding: 10px 40px;
      .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .profile-heading {
          font-size: 20px;
          font-weight: 500;
          text-align: left;
          color: #444;
        }
      }
      .details-container {
        .employee-info {
          .profile-data-display {
            border-radius: 0.2rem;
            .align();
            .border-bottom();
            margin-top: 0.5rem;
          }
          .phone-number-holder {
            display: flex;
            .phone-country-code {
              width: 15%;
              height: 43px;
              display: inline-block;
              .align();
              .border-bottom();
            }
            .phone-number {
              width: 78%;
              margin-left: 5px;
              .align();
              display: inline-block;
              .border-bottom();
            }
          }
        }
      }
      .child-information {
        margin-top: 15px;
        .child-information-header {
          font-size: 15px;
          font-weight: 400;
          text-align: left;
          color: #90919b;
        }
      }
      .parent-information {
        margin-top: 15px;
        .parent-information-header {
          font-size: 15px;
          font-weight: 400;
          text-align: left;
          color: #90919b;
        }
        .employee-info {
          .fields-heading {
            margin-top: 16px;
            font-size: 1rem;
            text-align: left;
            color: @color-Manatee;
          }

          .profile-data-display {
            border-radius: 0.2rem;
            .align();
            .border-bottom();
          }
          .phone-number-holder {
            .phone-country-code {
              width: 20%;
              display: inline-block;
              .align();
              .border-bottom();
            }
            .phone-number {
              width: 78%;
              margin-left: 5px;
              .align();
              display: inline-block;
              .border-bottom();
            }
          }
        }
      }
    }
  }
}
.add-button-container,
.add-button-container:active,
.add-button-container:focus,
.add-button-container:hover {
  display: flex;
  align-items: center;
  color: #fea101;
  margin-top: 4px;
}

.checkbox-container {
  padding: 15px;
}

.employee-card-container {
  position: relative;
  background-color: @color-White;
  border-radius: 10px;
  height: 100%;
  padding: 3rem;
  margin-top: 10px;
  height: auto;

  .card-heading {
    text-align: left;
    font-size: 1.3rem;
    font-weight: 600;
  }
  .dragger-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dragger-items-container {
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      .card-container {
        height: 80%;
        width: 100%;
      }
    }
  }
}
.employe-border {
  width: 80%;
  margin-left: 2rem;
  border-bottom: 1px solid #d5d6df;
}
.employe-details-container {
  padding: 0.8rem 2rem;
}

.employe-info {
  margin: 5px 0;
  .employe-info-header {
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
  }
  .employe-info-data {
    font-size: 9px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    text-align: left;
    color: var(--dark-grey);
  }
}

.salary-details-container {
  .ctc-label {
    color: #505057;
  }

  .amount {
    margin-top: 0.4rem;
    font-size: 1.25rem;
    font-weight: 600;
    color: @color-Shark;
  }

  .salary-breakdown-table-container {
    .salary-breakdown-table {
      text-align: left;
      width: 100%;
      max-width: 50rem;
      .salary-breakdown-table-head {
        font-size: 1rem;
        color: @color-ManateeLite;
        font-weight: 200;
        border: 1px solid @color-ManateeLite;

        .salary-breakdown-table-th-1 {
          width: 55%;
          padding: 1rem 8px;
        }
        .salary-breakdown-table-th-2 {
          text-align: right;
          width: 20%;
        }
        .salary-breakdown-table-th-3 {
          width: 25%;
          text-align: right;
          padding: 1rem 8px;
        }
      }

      .salary-breakdown-table-body {
        font-size: 0.85rem;
        color: @color-Tundora;
        font-weight: 500;
        border: 1px solid @color-ManateeLite;
        padding: 0.75rem 0px;

        .salary-breakdown-table-td-1 {
          padding: 0.75rem 8px;
        }
        .salary-breakdown-table-td-2 {
          padding: 0.5rem 0px;
          text-align: right;
        }
        .salary-breakdown-table-td-3 {
          padding: 0.5rem 8px;
          text-align: right;
        }
      }

      .salary-breakdown-table-footer {
        border: 1px solid @color-ManateeLite;
        border-width: 1px 0;
        font-size: 1rem;
        color: @color-SharkLite;
        font-weight: 500;

        .salary-breakdown-table-footer-td-2 {
          padding: 0.75rem 0px;
          text-align: right;
        }
        .salary-breakdown-table-footer-td-3 {
          text-align: right;
        }
      }
    }
  }
}

.fields-heading {
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  color: @color-Manatee;
}

.drawer {
  margin: 50px;
}

.ant-row {
  margin-bottom: 20px;
}

.info {
  font-weight: bold;
}

.education {
  margin: 15px 0 32px;
  padding: 13.5px 31px 13px 16.5px;
  border-radius: 5px;
  background-color: #f8fbff;
}

.userIDLabel {
  width: 47.5px;
  margin: 2.5px 0 1.5px 10px;
  font-size: 10px;
  padding: 3px 4.5px 3px 5px;
  border-radius: 1.5px;
  background-color: #048c7f;
  margin: 2.5px 0 1.5px 10px;
  padding: 3px 4.5px 3px 5px;
  border-radius: 1.5px;
  background-color: #048c7f;
  font-size: 10px;
  font-weight: 500;
  color: white;
}
