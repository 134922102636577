.dropdowndiv{
  position: relative !important;
}
.ant-select-arrow .anticon {
  visibility: hidden !important;
}

.addMoreDiv:hover{
  cursor: pointer;
}
.dropdowndiv .labeldropdown{
  color:#9898a5;
  position: absolute;
  left: 0.4rem;
  padding: 0 6px;
  top:0.7rem;
  font-size: 1rem;
  font-weight: 500;
  z-index: 0 !important;
  transition: top 0.2s ease-in,
  left 0.2s ease-in,
  font-size 0.2s ease-in;
  background-color: white;
  cursor: text;
}
.dropdowndiv .ant-select{
  background-color: transparent !important;
  z-index: 1 !important;
  border: 1px #dbdbe5 solid;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent !important;
}
.dropdowndiv .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 50px !important;
}
.dropdowndiv .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 8px !important;
}
.dropdowndiv .ant-select-single .ant-select-selector {
  display: flex;
  align-items: center !important;
}
.dropdowndiv .ant-select-focused~.labeldropdown
{
  top: -0.6rem;
  font-size: 14px;
  left: 0.5rem;
  z-index: 2 !important;
  padding: 0 6px;
  color: #405cd2 !important;
}
.ant-select-focused .ant-select-arrow .anticon{
  visibility: visible !important;
}
.dropdowndiv .labelup
{
  position: absolute;
  padding: 0 6px;
  color: #9898a5;
  font-weight: 500;
  cursor: text;
  transition: top 0.2s ease-in,
  left 0.2s ease-in,
  font-size 0.2s ease-in;
  background-color: white;
  top: -0.6rem;
  font-size: 14px;
  left: 0.5rem;
  color: #9ea0a8 !important;
  z-index: 2;
}
.dropdowndiv .ant-select-focused~.labelup
{
  color: #405cd2 !important;
}
.dropdowndiv .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 0px solid #dbdbe5 !important;
  border-radius: 0px;
  padding: 0.5rem 13px !important;
  box-shadow: 0 0 0 !important;
}
.dropdowndiv .ant-select:not(.ant-select-focused):hover{
border-color: #9c9ca1 !important;
}
.dropdowndiv .ant-select-focused {
border: 2px solid #405cd2 !important;
}
.ant-select:not(.ant-select-focused) ~ .labelup{
color: #5E5F62 !important;
}



@primary-color: #405CD2;