.passworddiv {
  position: relative;

  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 2px #405cd2 solid !important;
    box-shadow: 0 0 0 0 !important;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #405cd2 !important;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-focused):not(.error):hover {
    border-color: #9c9ca1 !important;
  }

  .ant-input {
    z-index: 1 !important;
    font-weight: 500;
    background-color: transparent !important;

    &:focus~.ant-input-suffix {
      visibility: visible !important;
    }
    &[disabled]{
      border-bottom: 0 !important;
    }
  }

  .labelnormal {
    z-index: 0;
    position: absolute;
    left: 0.4rem;
    top: 0.7rem;
    padding: 0 6px;
    color: #9898a5;
    font-size: 1rem;
    font-weight: 500;
    cursor: text;
    transition: top 0.2s ease-in,
      left 0.2s ease-in,
      font-size 0.2s ease-in;
    background-color: white;

    &.error {
      color: #F05050;
      top: -0.6rem;
      font-size: 14px;
      left: 0.5rem;
      padding: 0 6px;
      z-index: 2 !important;
    }
    &.success{
      color: green;
      top: -0.6rem;
      font-size: 14px;
      left: 0.5rem;
      padding: 0 6px;
      z-index: 2 !important;
    }
  }

  .ant-input-affix-wrapper-focused {
    ~.labelnormal {
      color: #405cd2;
      top: -0.6rem;
      font-size: 14px;
      left: 0.5rem;
      padding: 0 6px;
      z-index: 2 !important;
    }

    ~.labelup {
      color: #405cd2;
    }
  }

  .ant-input-affix-wrapper {
    border: 1px solid #dbdbe5;
    padding: 4px 13px !important;

    &:not(.ant-input-affix-wrapper-focused)~.labelup:not(.error) {
      color: #5E5F62 !important;
    }
  }

  .anticon svg {
    display: inline-block;
    color: #405cd2 !important;
  }

  .ant-input-suffix {
    visibility: hidden !important;
  }

  .labelup {
    z-index: 2;
    position: absolute;
    left: 0.5rem;
    top: -0.6rem;
    padding: 0 6px;
    color: #9898a5;
    font-size: 14px;
    font-weight: 500;
    cursor: text;
    transition: top 0.2s ease-in,
      left 0.2s ease-in,
      font-size 0.2s ease-in;
    background-color: white;

    &.error {
      color: #F05050 !important;
      animation: shake 0.2s ease-in-out 0s
    }
    &.success {
      color: green !important;
    }
  }
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }

  25% {
    margin-left: 0.5rem;
  }

  75% {
    margin-left: -0.5rem;
  }

  100% {
    margin-left: 0rem;
  }
}
.errormessage{
  visibility: hidden;
  margin-top:2px;
}
.activeerrormessage{
  visibility:visible; 
  color:#F05050;
  margin-top:2px;
  display:block
}
.defaultmessage{
  visibility:hidden; 
  color:#405cd2;
  margin-top:2px;
  display:block
}
.activedefaultmessage{
  visibility:visible; 
  color:#405cd2;
  margin-top:2px;
  display:block
}
.activesuccessmessage{
  visibility:visible; 
  color:green;
  margin-top:2px;
  display:block
}
@primary-color: #405CD2;