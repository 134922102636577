.salary-break {
  font-size: 13px;
  line-height: 1.19;
  text-align: left;
}
.ant-slider-dot {
  width: 1rem !important;
  height: 1rem !important;
  top: -3px;
  background: #44a51c;
}
.salary-break-size {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.19;
  text-align: left;
  color: @color-Denim;
}
.salary-container {
  border-radius: 10px;
  margin: 0px auto;
  margin-top: 25px;
  width: 100%;
  overflow-x: hidden;
  .display-content-center();

  .manage-salary {
    width: 95%;
    flex: none;
    background-color: @color-White;
    padding: 20px;
    border-radius: 10px;

    @media (max-width: 1200px) {
      padding: 15px;
    }

    .graph-data {
      h2 {
        font-size: 30px;
        .font-style-left();
        line-height: 1.23;
        color: @color-Tundora;
      }
      @media (max-width: 600px) {
        font-size: 25px;
      }
      .displayflex-horizontal {
        .display-center-content-space-between();

        .ctc-data-container {
          margin: 20px 0;

          @media (max-width: 1200px) {
            margin: 10px 0;
          }

          .card-data-heading {
            font-size: 12px;
            color: @color-Manatee;
            font-weight: 300;
            margin-top: 20px;
          }

          .currency-format {
            font-size: 16px;
            color: @color-Manatee;
            font-weight: 400;
            margin-right: 16px;
          }

          .amount {
            font-size: 15px;
            font-weight: 600;
            @media (max-width: 600px) {
              font-size: 20px;
            }
          }
        }
      }
      .chart-container {
        margin: 1rem 0;
      }
      .info-container {
        margin: 1rem auto;
        padding: 9px;
        text-align: center;
        font-size: 10px;
        width: auto;
        color: #40d2ac;
        background-color: #e4fff8;
        border-radius: 5px;
      }
    }



    .slider {
      padding: 20px 30px 30px;
      border-radius: 5px;
      background-color: #405cd2;
      margin-bottom: 10px;

      @media (max-width: 1200px) {
        height: auto;
        width: 100%;
      }

      .slider-header {
        .display-center-content-space-between();
        width: 100%;
        margin-bottom: 20px;

        h3 {
          color: @color-White;
          font-weight: 600;
          font-size: 25px;
          @media (max-width: 600px) {
            font-size: 20px;
          }
        }
      }

      .slider-container {
        .display-justify-content-space-between();
        width: 100%;
        height: 100%;
        p {
          font-size: 12px;
          font-weight: bold;
          color: @color-White;
        }
      }

      .ant-slider-rail {
        height: 0.5rem !important;
        background-color: @color-White;
      }

      .ant-slider-track {
        height: 0.5rem !important;
      }
      .edit-container {
        .display-flex-column();
        justify-content: flex-start;
        align-items: flex-end;
        padding: 10px 0;
        width: 40%;
        height: 100%;
        .submit-container {
          width: 100%;
          display: flex;
        }
      }
    }
  }
}

.ant-slider-mark-text {
  color: @color-White;
}

.salary-breakdown-info-container {
  margin: 16px 0px;
  padding: 24px 0px;
  .display-justify-content-space-between();

  @media (max-width: 600px) {
    .display-flex-column-justify-content-center();
  }

  .separator {
    width: 2px;
    background-color: #d5d6da;
  }
  .ctc-data-container {
    margin-left: 10px;
    .card-data-heading {
      margin-bottom: 5px;
      font-size: 0.7rem;
      font-weight: 500;
      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
    .currency-format {
      @media (max-width: 600px) {
        font-size: 15px;
      }
    }
    .amount {
      font-size: 1.1rem;
      font-weight: 600;
    }
  }

  .salary-right-card-amount {
    text-align: right;
  }
}


.break-down {
  height: auto;
  background-color: @color-AthensGrey;
  border-radius: 5px;
  margin-top: 10px;

  .break-down-section {
    padding: 30px;
  }
  .break-down-section-left {
    border-right: 0.2px solid @color-Mischka;
    @media (max-width: 600px) {
      border-right: none;
    }
  }

  .padding-breakdown {
    padding: 0 30px;
  }

  h3 {
    font-size: 15px;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: @color-SharkLite;
    margin: 10px 0;
  }

  .individual-structure {
    .display-center-content-space-between();
    width: 100%;
    padding: 10px 0;

    .break-label {
      .salary-break();
      font-weight: 400;
      color: @color-MidGray;
    }

    .break-value {
      .salary-break();
      color: @color-MidGray;
    }

    .break-label-earning {
      .salary-break();
      font-weight: 600;
      color: @color-Shamrock;
    }

    .break-value-earning {
      .salary-break();
      font-weight: 600;
      color: @color-Shamrock;
    }
    .break-label-deduction {
      .salary-break();
      font-weight: 600;
      color: @color-Froly;
    }

    .break-value-deduction {
      .salary-break();
      color: @color-Froly;
    }
  }
  .individual-structure-takehome {
    .display-center-content-space-between();
    width: 100%;
    height: 50px;
    padding: 10px 30px;
    border-top: 0.2px solid @color-Mischka;

    .break-label {
      .salary-break-size();
    }

    .break-value {
      .salary-break-size();
    }
  }
}