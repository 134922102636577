.border-bottom {
  border-bottom: solid 0.5px @color-AthensGray;
}
.align {
  text-align: left;
  color: @color-Shark;
  font-weight: 500;
  font-size: 15px;
  padding: 8px 2px;
}

.profile-contanier {
  min-height: calc(100vh - 150px);
  background-color: @color-AthensGrey;
  width: 100%;
  overflow-x: hidden;
  padding: 10px;
  height: 500px;

  .pane-heading {
    padding: 0px 0px 1rem 0px;
    font-size: 1.3em;
    font-weight: 500;
  }
}

.employee-header-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .employee-arrow-outlined {
    color: #151b30;
    font-size: 18px;
    margin-left: 5px;
    font-weight: 400;
    text-align: left;
  }
  .employee-name {
    color: #151b30;
    font-size: 25px;
    margin-left: 10px;
    font-weight: 400;
    text-align: left;
  }
  .employee-id {
    width: auto;
    height: auto;
    margin-left: 15px;
    background-color: #f7f7fa;
    color: #999ba7;
    padding: 3px;
  }
}

.employee-basic-info {
  .display-align-center();
  margin-bottom: 24px;

  .employee-logo-container {
    margin-right: 32px;

    .employee-logo {
      background-color: @color-OrangePeel;
      border-radius: 50%;
    }
  }

  .employee-name-container {
    margin-right: 32px;
    .employee-name-displayname {
      font-size: 30px;
      font-weight: 500;
    }

    .employee-name-designation {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .active-button,
  .active-button:hover {
    border: none;
    background-color: @color-FrostedMint;
    color: @color-Java;
    border-radius: 0.5rem;
  }

  .separation-button {
    border: none;
    background-color: #f7d8d8;
    color: #f26b6b;
    border-radius: 0.5rem;
    margin: 0px 16px;
    @media (max-width: 600px) {
      margin-bottom: 10px;
    }
  }
}

.information-section {
  width: 90%;
  margin-left: 2rem;
  .section-header {
    .display-center-content-space-between();
    margin: 15px 0;
    .information-category-heading {
      .font-style-left();
      line-height: 1.28;
      color: @color-Tundora;
      font-size: 20px;
    }
  }
  .divider {
    border-right: 1px solid @color-AthensGray;
  }
  .employee-info {
    .fields-heading {
      margin-top: 16px;
      font-size: 1.1rem;
      text-align: left;
      color: @color-Manatee;
    }

    .profile-data-display {
      border-radius: 0.2rem;
      .align();
      .border-bottom();
    }
    .phone-number-holder {
      .phone-country-code {
        width: 20%;
        display: inline-block;
        .align();
        .border-bottom();
      }
      .phone-number {
        width: 78%;
        margin-left: 5px;
        .align();
        display: inline-block;
        .border-bottom();
      }
    }
  }
  .monthly-payslip-container {
    overflow: auto;
    width: 100%;
    max-height: 340px;
    margin-top: 10px;

    .monthly-payslips {
      .display-center-content-space-between();
      padding: 15px 2px;
      color: @color-Jumbo;
      text-align: left;

      .border-bottom();
      span {
        text-align: left;
        font-size: 13px;
        color: @color-Jumbo;
      }
      .pay-silp-view-button {
        background-color: @color-White;
        .border();
        span {
          color: @color-OrangePeel;
        }
      }
    }

    hr {
      border: @color-WildSand solid 1px;
    }
  }
}
.ctc-container {
  .ctc-header {
    .label-ctc {
      font-weight: normal;
      text-align: left;
      font-size: 18px;
      color: @color-FrenchGray;
    }
    .ctc {
      font-weight: bold;
      font-size: 25px;
      color: @color-Shark;
    }
  }
  .ctc-breakage {
    display: flex;
    height: 50px;
    margin: 15px 0;
    .ctc-breakage-data {
      .document-viewer();
      height: 100%;

      p {
        font-size: 10px;
        font-weight: 400;
      }
    }
  }
  .graph-date-labels {
    .graph-date-labels-info {
      .display-center-content-space-between();
    }

    .graph-info {
      span {
        height: 10px;
        width: 10px;
        display: inline-block;
        margin-right: 5px;
        border-radius: 50%;
      }
    }
    .graph-info-amount {
      font-weight: 500;
    }
  }
}

.employee-actions {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  .bonus-container {
    height: 350px;
    width: 100%;
    background-color: @color-White;
    padding: 10px 20px;
    border-radius: 10px;

    .bonus-details {
      width: 100%;
      height: 250px;
      overflow-y: scroll;

      .bonuses-data {
        .display-center-content-space-between();
        padding: 15px 2px;
        color: @color-Jumbo;
        text-align: left;
        .border-bottom();

        span {
          text-align: left;
          font-size: 13px;
          color: @color-Jumbo;
        }
        .pay-silp-view-button {
          background-color: @color-White;
          border: none;
          color: @color-OrangePeel;
        }
      }
    }
  }
  .deduction {
    .document-viewer();
    height: 200px;
    width: 100%;
    background-color: @color-White;
    padding: 10px 20px;
    margin: 0px;
    margin-top: 30px;
    border-radius: 10px;

    .deductiontion-Logo {
      .document-viewer();
      height: 90px;
      width: 90px;
      border-radius: 50%;
    }
    .deduction-name {
      margin-left: 20px;
      width: 60%;

      .deduction-heading {
        font-weight: bold;
        font-size: 23px;
        color: @color-Shark;
      }
      .deduction-subname {
        font-weight: normal;
        text-align: left;
        font-size: 15px;
        color: @color-FrenchGray;
        white-space: wrap;
      }
    }
  }
}

.submit-container {
  margin: 10px 0;
  text-align: left;
  .submit-button {
    margin-top: 20px;
  }
}

.payslip-and-forms-container {
  background-color: @color-White;
  border-radius: 10px;
  padding: 4rem;
  width: 95%;
  height: auto;
  margin: 0px auto;
  .payslip-container {
    .payslip-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .payslip-heading {
        font-size: 20px;
        font-weight: 500;
        text-align: left;
        color: #2d2e30;
      }
    }
  }
}

.status-container {
  padding: 20px;
  .status-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .status-header {
      font-size: 18px;
      font-weight: 500;
      text-align: left;
      color: #444;
    }
  }
  .current-status {
    margin-top: 1.5rem;
    .current-status-header {
      font-size: 15px;
      font-weight: 500;
      text-align: left;
      color: #405cd2;
    }
    .text-area {
      width: 60%;
      margin-top: 1.5rem;
      border: 1px solid #bcbdc8;
    }
    .comments-container {
      margin-top: 12px;
      .image {
        height: 60px;
        width: 60px;
        background-color: @color-OrangePeel;
        border-radius: 50%;
        margin-right: 16px;
      }
      .name {
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        color: #444;
      }
      .head {
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: #bcbdc8;
      }
      .comment {
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #90919b;
      }
    }
    .status-display {
      margin-top: 10px;
      .application {
        font-size: 15px;
        padding: 10px;
        border-radius: 3px;
        font-weight: 600;
      }
      .application-accepted {
        background-color: #e4fff8;
        color: #40d2ac;
        .application();
      }
      .application-pending {
        background-color: #e9aca1;
        color: #e94c4c;
        .application();
      }
    }
    .current-status-container {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .current-status-label {
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #5f5f65;
      }
      .current-status-data {
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        color: #151b30;
      }
    }
    .days-left-container {
      margin-top: 10px;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .days-left-label {
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #5f5f65;
      }
      .days-left-data {
        font-size: 18px;
        font-weight: 500;
        text-align: left;
        color: #405cd2;
      }
    }
  }
}

.full-and-final-container {
  padding: 15px;
  .final-info-container {
    .final-info-header {
      font-size: 16px;
      font-weight: 500;
      text-align: left;
      color: #444;
    }
  }
}

.employee-deduction-file-container {
  height: 500px;
  .employee-deduction-files-body {
    padding: 10px;
    height: calc(80vh - 130px);
    overflow-y: scroll;
    width: 100%;
    .employee-deduction-files-container {
      width: 60%;
      margin: 10px;
      .employee-deduction-heading {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
  .employee-deduction-files-category-holder {
    background-color: #f6f6fb;
    border-radius: 5px;
    margin: 16px 0px;
    padding: 16px;
    font-size: 16.2px;
    font-weight: 500;
  }
}
