.payroll {
    .line {
      height: 2px;
      width: 100%;
      background-color: #d5d6df;
      position: fixed;
    }
    .payroll-container {
      background: #fff;
      width: 95%;
      margin: 1rem auto;
      border-radius: 10px;
      padding: 2rem;
    }
    .single-payroll-selected {
      padding: 2rem 2rem;
      background-color: #405cd2;
      color: white;
      border-radius: 7px;
      &-heading {
        h3 {
          font-size: 1.2rem;
          color: white;
          font-weight: 600;
        }
      }
      .left-flex {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1.5;
        .group {
          h4 {
            color: #f8f8ff;
            font-weight: 400;
            font-size: 0.9rem;
          }
          h3 {
            color: white;
          }
        }
      }
      .right-flex {
        flex: 1;
        div {
          float: right;
        }
      }
    }
    .single-payroll {
      padding: 2rem;
      border-bottom: solid 0.5px #d5d6df;
      &-heading {
        h3 {
          font-size: 1.2rem;
          font-weight: 600;
        }
      }
      .left-flex {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1.5;
        .group {
          h4 {
            font-weight: 400;
            font-size: 0.9rem;
          }
          h3 {
            color: #405cd2;
            font-weight: 600;
          }
        }
        .last-group {
          h4 {
            font-weight: 400;
            font-size: 0.9rem;
          }
          h3 {
            color: black;
            font-weight: 700;
          }
        }
      }
      .right-flex {
        flex: 1;
        div {
          float: right;
        }
        .view {
          display: none;
        }
      }
      &:hover {
        background-color: #405cd2;
        border-radius: 7px;
        cursor: pointer;
        .single-payroll-heading {
          h3 {
            font-size: 1.2rem;
            color: white;
            font-weight: 600;
          }
        }
        .left-flex {
          .group {
            h4 {
              color: #f8f8ff;
              font-weight: 400;
              font-size: 0.9rem;
            }
            h3 {
              color: white;
            }
          }
        }
        .right-flex {
          .view {
            display: block;
            p {
              color: white;
            }
          }
        }
        .last-group {
          h4 {
            font-weight: 400;
            font-size: 0.9rem;
            color: white;
          }
          h3 {
            color: #fff;
            font-weight: 700;
          }
        }
      }
    }
    .payroll-status-draft {
      background-color: #f8f8ff;
      padding: 0.2rem 0.8rem;
      border-radius: 3px;
      margin-left: 2rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      p {
        margin-left: 0.5rem;
        font-size: 0.8rem;
        color: #08080a;
      }
      img {
        width: 0.8rem;
      }
    }
    .payroll-status-processed {
      background-color: #20ad89;
      padding: 0.2rem 0.8rem;
      border-radius: 3px;
      margin-left: 2rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      p {
        margin-left: 0.5rem;
        font-size: 0.8rem;
        color: #fff;
      }
      img {
        width: 0.8rem;
      }
    }
  }