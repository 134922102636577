@width: 100%;
.width-height {
  width: @width;
  height: auto;
}
.login-container {
  height: 100vh;
  width: @width;
  .display-align-center-justify-content-space-around();
  background-image: url("../../assets/common/Mask Group 1.svg"),
    url("../../assets/common/Rectangle 3.svg");
  .img-background();
  opacity: 1;

  @media (max-width: 500px) {
    .width-height();
    padding: 10px;
  }

  .login-contianer {
    width: 40%;
    .display-align-center-justify-center-flex-column();

    @media (max-width: 500px) {
      .width-height();
      padding: 10px;
    }

    .login-form-card {
      position: relative;
      width: 30rem;
      height: 43rem;
      background: @color-White 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 60px @color-GreyDark;
      border-radius: 1rem;
      opacity: 1;

      @media (max-width: 500px) {
        .width-height();
        padding: 10px;
      }

      .card-title-container {
        padding-left: 1rem;
        font-size: 1rem;
        font-weight: 300;
        height: 3.5rem;
        background: @color-Mariner 0% 0% no-repeat padding-box;
        border-radius: 15px 15px 0px 0px;
        opacity: 1;
        color: @color-White;

        .card-title-text {
          position: absolute;
          padding-top: 3%;

          .card-title-icon {
            display: inline-block;
            padding-right: 1rem;
          }
        }
      }

      .card-text-holder {
        padding: 3rem;
        padding-bottom: 1rem;

        .card-text {
          font-weight: 600;
          font-size: 2rem;
        }
      }

      .login-info-holder {
        padding: 3rem;
        padding-top: 0px;

        .login-info-text {
          color: @color-ManateeLite;
          font-size: 1rem;

          .input-box {
            width: @width;
            border-style: hidden;
            border-bottom: 0.01rem @color-ManateeLite solid;
            margin-top: 0.5rem;
            outline: none;
            font-size: 1rem;
          }
        }

        .button {
          display: block;
          background-color: @color-Shamrock;
          border-color: @color-Shamrock;
          padding-left: 2rem;
          padding-right: 2rem;
          margin-top: 2.25rem;
          border-radius: 0.7rem;
        }

        .stayLoggedIn-checkbox {
          color: @color-ManateeLite;
          display: block;
          font-size: 1rem;
          margin-top: 1.25rem;
        }
      }

      .user-info-holder {
        .display-align-center();
        padding-left: 3rem;
        margin-bottom: 3rem;

        .employee-avatar {
          background-color: @color-MacaroniandCheese;
          padding: 0.6rem;
          border-radius: 50%;
          width: 70%;
        }

        .organisation-avatar {
          background-color: @color-PeachCream;
          padding: 0.8rem;
          border-radius: 50%;
          width: @width;
        }

        .user-info {
          .display-flex-column-justify-content-space-around();
          width: @width;

          p {
            padding: 0px;
            margin: 0px;
          }
        }

        .userType {
          font-size: 0.8rem;
          color: @color-ManateeLite;
        }

        .userName {
          font-size: 1rem;
          color: @color-Shark;
          font-weight: 400;
        }
      }

      .additional-links {
        padding: 3rem;
        width: @width;
        position: absolute;
        bottom: 0px;
        font-size: 1rem;
        text-align: center;

        @media (max-width: 500px) {
          .width-height();
          padding: 1rem;
          position: static;
        }

        a {
          display: block;
          color: @color-ManateeLite;
          padding-top: 2rem;
        }
      }

      .additional-links-for-password {
        padding: 3rem;
        padding-bottom: 2rem;
        width: @width;
        position: absolute;
        bottom: 0px;
        font-size: 1rem;
        text-align: left;

        @media (max-width: 500px) {
          .width-height();
          padding: 1rem;
          position: static;
        }

        .additional-links-for-password-hr {
          border: none;
          border-bottom: 1px solid @color-AthensGray !important;
          margin-bottom: 2rem;
        }

        span {
          display: block;
          color: @color-Mariner;
          margin: 0px;
          margin-top: -0.5rem;
          margin-bottom: -0.5rem;
          padding: 0px;
          padding-bottom: 0.1rem;
        }
      }
    }
  }

  .hero-container {
    width: 40%;
    .display-align-center-justify-center-flex-column();

    @media (max-width: 1100px) {
      display: none;
    }

    .skeleton1 {
      align-self: flex-end;
      padding-bottom: 1rem;
      width: 30%;
    }

    .heroImage {
      align-self: flex-end;
      padding: 1rem;
      padding-bottom: 5rem;
      width: 90%;
    }

    .skeleton2 {
      align-self: flex-start;
      padding: 1rem;
      width: 35%;
    }
  }
}

.input-login.ant-input,
.input-login.ant-input-password,
.input-login.ant-input-affix-wrapper {
  width: @width;
  .border();
  border-bottom: 1.5px @color-ManateeLite solid;
  height: 32px;
  font-size: 17px;
  padding: 0px;
  margin-top: 16px;
}

.input-register.ant-input,
.input-register.ant-input-password,
.input-register.ant-input-affix-wrapper {
  width: @width;
  .border();
  border-bottom: 1.5px solid #eaeaee;
  font-size: 17px;
  height: 50px;
  padding: 0px;
  margin: 5px 0 10px;
}

.input-register.ant-input-hover,
.input-register.ant-input:focus,
.input-register.ant-input-password:hover,
.input-register.ant-input-password:focus,
.input-register.ant-input-password:active,
.input-register.ant-input-affix-wrapper:hover,
.input-register.ant-input-affix-wrapper:focus {
  border: none !important;
  border-bottom: @color-Mariner solid 1.5px !important;
}

.input-login.ant-input-hover,
.input-login.ant-input:focus,
.input-login.ant-input-password:hover,
.input-login.ant-input-password:focus,
.input-login.ant-input-password:active,
.input-login.ant-input-affix-wrapper:hover,
.input-login.ant-input-affix-wrapper:focus {
  border: none !important;
  border-bottom: @color-Mariner solid 2px !important;
}

.input-register.ant-input:focus + .register-label,
.input-register.ant-input:active + .register-label {
  color: @color-Mariner;
}

.register-label {
  color: @color-ManateeLite;
  font-size: 12px;
}
