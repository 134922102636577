.card-org-styling {
  border-radius: 10px;
  padding: 24px;
  background-color: @color-White;
}
.card-data-heading {
  font-size: 15px;
  color: @color-Manatee;
  font-weight: 300;
}

.number-card-container {
  margin-bottom: 48px;
  .number-card {
    height: 125px;
    .card-org-styling();
    .display-align-center();

    .card-logo {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      margin-right: 24px;
      .display-align-center-justify-content-center();
    }

    .card-data-container {
      .card-data {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}

.charts-card {
  height: 35rem;
  .card-org-styling();

  .card-data-heading {
    margin-bottom: 16px;
  }

  .amount-and-option-button-container {
    .display-center-content-space-between();

    .currency-format {
      font-size: 16px;
      color: @color-Manatee;
      font-weight: 300;
      margin-right: 16px;
    }

    .chart-amount {
      font-size: 48px;
      font-weight: 600;
      @media (max-width: 600px) {
        font-size: 30px;
        font-weight: 500;
      }
    }
  }
}

.ctc-calculator-container {
  .card-org-styling();
  width: 100%;
  height: 100%;

  .ctc-calculator-heading {
    font-size: 18px;
    font-weight: 600;
  }

  .financial-year {
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 32px;
    @media (max-width: 600px) {
      font-size: 30px;
      font-weight: 500;
    }
  }

  .ctc-heading-container {
    .display-justify-content-space-between();
    margin-top: 36px;
    margin-bottom: 36px;

    .download-slip-button {
      background-color: @color-PeachCream;
      color: @color-OrangePeel;
      border-radius: 10px;
      border: none;
    }
  }

  .line-2 {
    border-top: 2px solid @color-Nevada;
  }

  .ctc-items-container {
    color: @color-MidGray;
    font-size: 16px;
    font-weight: 400;

    .ctc-item {
      display: inline-block;
      margin-bottom: 24px;
      width: 70%;
    }

    .ctc-item-value {
      display: inline-block;
      width: 30%;
      text-align: right;
    }
  }

  .reset-button {
    border: none;
    margin-left: 65px;
  }
}

.ctc-calc {
  height: 100%;
  background-color: @color-AthensGrey;
  border-radius: 7px;
  padding: 21px 15.5px 11px 21px;
  overflow-y: scroll;

  h3 {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    color: @color-SharkLite;
    margin: 20px 0;
  }
  .break-down-value {
    height: 90%;
    .display-flex-column-justify-content-space-around();
    align-items: center;
  }
}
