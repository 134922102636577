.profile-contaniers {
  overflow-x: hidden;
  min-height: 80vh;
  background-color: @color-TomGray;
  .org-config-container {
    background-color: @color-White;
    padding: 1.5rem;
    border-radius: 10px;
    margin-bottom: 10px;

    .pane-heading {
      padding: 0px 0px 1rem 0px;
      font-size: 1.3em;
      font-weight: 500;
      .display-center-content-space-between();
      .headings {
        @media (max-width: 600px) {
          font-size: 1.1em;
          font-weight: 500;
        }
      }
    }
    .allowance-add-info {
      background-color: @color-TitanWhite;
      padding: 6px 11px;
      margin: 10px 0px;
      .display-center-content-space-between();
      border-radius: 10px;
      p {
        font-weight: 400;
        font-size: 15px;
      }
    }
    .config-container {
      width: 100%;

      .radio-group {
        width: 100%;
        margin: 25px 0;

        p {
          text-align: left;
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 0px;
          margin-bottom: 5px;
        }
      }
    }
    .allowances-config {
      max-height: calc(70vh - 75px);
      overflow-y: scroll;
      padding: 5px, 0;
      scrollbar-width: thin;
      width: 100%;
      .single-allowance {
        .display-center-content-space-between();
        border: none;
        padding: 5px;
        height: 50px;

        .allownace-action-container {
          .display-align-center-justify-content-center();
          height: 1rem;
        }

        p {
          font-size: 18px;
        }
      }
    }
    .custom-allowances {
      .display-center-content-space-between();
      margin: 10px 0;
    }
  }
}

.allowance-container {
  .nav-tabs {
    cursor: pointer;
    font-size: 16px;
    color: @color-FrenchGray;
    font-weight: 400;
  }

  .nav-tab-active {
    border-bottom: 2px solid @color-Mariner;
    color: @color-Mariner;
  }
}

.groups-card {
  background-color: @color-titanWhite;
  padding: 16px;
  border-radius: 10px;

  .buton-container {
    justify-content: space-between;
    display: flex;
    width: 150px;
    align-items: center;
  }

  .group-name {
    color: @color-Mariner;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 16px;
  }

  .groups-allowances {
    display: inline-block;
    padding: 1px 12px;
    margin: 0px 10px 8px 0px;
    border: 1px solid @color-havelockBlue;
    color: @color-havelockBlue;
    border-radius: 50px;
    font-weight: 400;
    font-size: 14px;
  }
}

.group-data-section {
  margin-top: 16px;

  .group-data-section-heading {
    color: @color-Mariner;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 8px;
  }

  .group-setting-name {
    color: @color-havelockBlue;
    font-weight: 400;
    font-size: 15px;
  }
  .group-setting-value {
    color: @color-Mariner;
    font-weight: 400;
    font-size: 15px;
  }
}

.allowance-modal-list {
  padding-top: 0px;
  max-height: 400px;
  overflow-y: auto;
}

.group-modal-setting-container {
  width: 330px;
}

.group-modal-allowance-container {
  width: 330px;
}

.full-separator {
  width: 2px;
  background-color: #e6e6e6;
}
