.salary-struct >.ant-modal-content> .ant-modal-footer{
    text-align: left;
    padding: 21px 45px;
}

.salary-struct{
    .ant-modal-close{
        top:3rem;
        right:2rem;
    }
}

@primary-color: #405CD2;