.flexContainer{
    display: flex;
    margin-bottom: 21px;
}

.heading{
    width:80%;
}

.searchBar{
    width:30%;
    justify-content: right;
}
@primary-color: #405CD2;