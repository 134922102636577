.rebalance {
  .card-container {
    padding: 2rem 2rem 0 2rem;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
  }

  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top
    > div
    > .ant-tabs-nav
    .ant-tabs-tab
    + .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-bottom
    > div
    > .ant-tabs-nav
    .ant-tabs-tab
    + .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 7px 7px 0 0;
    outline: none;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    background-color: #000;
    border-radius: 7px 7px 0 0;
    outline: none;
    color: white;
  }
  .ant-tabs-content {
    border-radius: 7px;
    margin-bottom: 0;
    background-color: #fff;
    padding: 2rem;
  }

  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0;
  }

  .card-container {
    margin: 0 !important;
  }

  .drawer-btn {
    background-color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .ant-drawer-content-wrapper {
    width: 40vw !important;
  }

  .ant-drawer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ant-drawer-close {
    position: relative;
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #bcbdc8;
  }

  .drawer-btn {
    background-color: #40d2ac;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    margin: 5px;
    font-weight: 400;
    letter-spacing: 0.75px;
    font-size: 15px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    &:hover {
      background-color: #39ebbb;
    }
    &:active {
      background-color: #29cea2;
    }
    @media (max-width: 600px) {
      font-size: 14px;

      .button-lib-content {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .ant-drawer-content-wrapper {
    width: 600px !important;
  }
  .ant-drawer-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.ant-drawer-header-title {
  flex-direction: row-reverse;
}

.ant-drawer-body {
  padding: 0 !important;
}
.drawer-content-container {
  .warning {
    background-color: #ffe8e8;
    padding: 0.8rem 1rem 0.8rem 2rem;
    p {
      color: #de2424;
      font-weight: 600;
    }
  }
}

.table-container {
  position: relative;
  padding: 1rem 2rem;
  // tr.head-tr {
  //   border-bottom: 0.5px solid #dfdfdf;
  // }
  h2 {
    font-size: 0.9rem;
    font-weight: 700;
    padding-bottom: 1rem;
  }
  th {
    color: #6e6e78;
    font-weight: 400;
    font-size: 0.7rem;
    width: 18%;
    margin: 0 1rem;
    text-align: left;
    &.item {
      padding-right: 0.5rem;
      width: 47%;
    }
  }
  td {
    padding: 0 1rem 1rem 0;
    text-align: start;
  }
}

.thin-line-breaker {
  height: 0.5px;
  width: 100% !important;
  background-color: #dfdfdf;
}

.table-container .allowance-drawer-table {
  th {
    min-width: 30%;
  }
  tr {
    margin-top: 1rem;
  }
}

@primary-color: #405CD2;