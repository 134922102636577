.section-full-data-container {
  margin: 0px 24px;


  .section-full-data-all-documents-anchor {
    color: @color-OrangePeel;
    display: inline-block;
    margin-bottom: 16px;

    .section-full-data-all-documents-anchor-data {
      display: inline-block;
      margin-right: 8px;
    }
  }

  .document-data-container {
    .display-justify-content-space-between();

    .document-data-container-heading {
      margin-bottom: 8px;
      font-size: 15px;
    }
  }

  hr {
    border-color: @color-Mischka;
    margin: 24px 0px;
  }

  .add-new-document-button {
    width: 100%;
    margin: 24px 0px;
    background-color: @color-AthensGray;
    color: @color-Manatee;
  }

  .document-data-buttons-container {
    .display-justify-content-space-between();
    width: 200px;
    margin: 10px auto;
  }
}

.section-data-container {
  text-align: left;
  width: 20rem;
  word-wrap: normal;

  .section-name {
    color: @color-Mariner;
    font-size: 1rem;
    font-weight: 600;
  }

  .section-type {
    color: @color-Jumbo;
    font-size: 1rem;
  }

  .section-info {
    color: @color-Jumbo;
    font-size: 0.8rem;
    width: 100%;
    max-width: 380px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.summary-container {
  padding: 24px;
  width: 600px;

  .summary-heading {
    font-size: 24px;
    font-weight: 400;
  }

  .summary-subheading {
    font-size: 14px;
    color: @color-Jumbo;
  }

  .investment-list-item-conatiner {
    .display-center-content-space-between();
    margin: 32px 0px;

    .investment-list-item-name {
      font-weight: 500;
      font-size: 18px;
    }

    .investment-list-item-type {
      color: @color-FrenchGray;
    }
    .investment-list-item-header {
      font-weight: 500;
    }
    .investment-list-item-price {
      font-weight: 300;
    }
  }

  .total-price {
    .display-center-content-space-between();
    margin-top: 36px;

    .total-price-heading {
      margin-right: 16px;
      color: @color-Manatee;
    }
  }
}

.document-list {
  margin: 10px 0px;
  .document-container {
    .display-center-content-space-between();
    margin: 12px 0;
    .file-typeHeader {
      width: 50px;
      height: 50px;
      margin: 0 10px 0;
      font-weight: 400;
      border-radius: 2px;
      background-color: @color-FairPink;
      color: @color-MediumCarmine;
      .display-center-content-space-between();
    }
    span {
      text-align: left;
      font-weight: 500;
      color: @color-MidGray;
    }
    .date-contianer {
      line-height: 2.14;
      font-size: 12px;
      letter-spacing: normal;
      text-align: left;
      color: @color-FrenchGray;
    }
    .verifiection-text {
      .display-align-center();
      flex-direction: row;
      letter-spacing: normal;
      font-size: 14px;
      text-align: left;
      float: right;
    }
  }
}

.document-groupp {
  .document-headerr {
    .display-center-content-space-between();
    padding: 10px 0px;

    .document-action-container {
      .display-center-content-space-between();
    }
    p {
      margin-left: 10px;
      font-size: 15px;
      font-weight: 500;
    }
    .allowance-amount {
      text-align: right;
      font-weight: 500;
      color: @color-OrangePeel;
    }
  }
}

.document-list-container {
  max-height: calc(100vh - 400px);
  overflow-y: scroll;
  .ant-card-bordered {
    border: none;
    border-bottom: 1px solid @color-AthensGray;
  }
}

.seperator {
  width: 90%;
  margin: 0px auto;
  height: 2px;
  background-color: #ebebeb;
}

.amount-details {
  margin-left: 64px;
  .display-flex-column-justify-content-center();
  align-items: flex-start;
}

.amount-container {
  .display-flex-direction-column-align-items-flex-start();
}

.amount-container-total {
  .display-align-center-justify-content-space-around();
}

.verifiection-logo {
  height: 16px;
  margin-right: 6px;
}

.verified {
  color: @color-Shamrock;
}
.not-verified {
  color: @color-OrangePeel;
}

.blue-tabs-deduction-container {
  .ant-tabs-nav {
    margin-top: 1rem;
  }

  .ant-tabs-left
    > .ant-tabs-content-holder
    > .ant-tabs-content
    > .ant-tabs-tabpane,
  .ant-tabs-left
    > div
    > .ant-tabs-content-holder
    > .ant-tabs-content
    > .ant-tabs-tabpane {
    padding-left: 0px;
  }

  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
    padding: 1px 20px;
    border-radius: 5px;
    width: 200px;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    background-color: #e1e6ff;
    margin-right: 16px;
  }

  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    margin: 5px 5px 0 0;
  }

  .ant-tabs-tab .ant-tabs-tab-btn {
    color: #232326;
    font-size: 15px;
    text-shadow: 0 0 0.25px currentColor;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #405cd2;
    text-shadow: 0 0 0.25px currentColor;
  }

  .ant-tabs-ink-bar {
    display: none;
  }
}

.blue-tabs-deduction-info-tabs-container {
  .ant-tabs-nav {
    margin-top: 0px;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    margin-right: 0px;
  }

  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
    padding: 0px;
    border-radius: 5px;
    width: 23rem;
  }

  .ant-tabs-tab .ant-tabs-tab-btn {
    padding: 16px 24px;
  }
}


.section-full-data-heading {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 10px;
}
.section-full-data-type {
  color: @color-Manatee;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 0.8rem;
}
.section-full-data-info {
  color: @color-Manatee;
  line-height: 20px;
  font-size: 14px;
  text-align: justify;
}