.tab-heading-1 {
  font-size: 26px;
  font-weight: 600;
  color: @color-Shark;
  margin-top: 4px;
  margin-bottom: 10px;
}

.tab-subtitle-1 {
  color: #505057;
  font-size: 16px;
  margin-bottom: 24px;
}

.table-heading {
  font-size: 16px;
  font-weight: 500;
  color: @color-Shark;
}

.dept-color-circle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.view-button {
  color: @color-Manatee;
  font-weight: 400;
}

.sketech-picker-container {
  position: absolute;
  top: 4rem;
  z-index: 1;
  right: 0;
}
.department-color-container {
  padding: 8px 1px 3px 1px;
  border-bottom: #eaeaee 2px solid;
  width: 400px;
  transition: all 0.3s;

  .dept-color-name {
    margin-left: 10px;
    font-weight: 400;
  }
}

.department-color-container:focus,
.department-color-container:active,
.department-color-container:hover {
  border-bottom: #405cd2 2px solid;
}

.payout-state-0-container {
  margin: 50px 0px;
}

.payout-state-0-image {
  width: 100%;
  max-width: 1000px;
}

.payout-details-container {
  .payout-details-sub-section-container {
    margin: 40px 24px;

    .sub-section-heading {
      color: @color-Mariner;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 16px;
    }

    .payout-details-document-dragger {
      height: 150px;
      display: flex;

      .payout-details-document-container {
        padding: 0px 20px;
      }
      .sub-section-dragger-image {
        height: 80px;
      }
      .sub-section-dragger-text {
        text-align: left;
        font-size: 14px;
        color: @color-Manatee;
        font-weight: 300;
      }
    }
  }
}
