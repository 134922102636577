.first-login-container {
  .first-login-flow-container();
  background-color: #fff;

  .stage-container {
    .stage-login-flow-container();

    .loginflowsteps {
      position: -webkit-sticky;
      position: sticky;
      background-color: white !important;
      top: -1px;
      z-index: 10;
      width: 100%;
      padding: 20px 40% 30px 0;
      //margin-left: -20px;
      @media (max-width: 1790px) {
        padding-right: 38rem;
        width: 100%;
      }
      @media (max-width: 575px) {
        margin-left: 0 !important;
        //margin: 25px 0;
        padding: 10px 0 20px 0;
        width: 100%;
      }
    }

    .stage-heading {
      .stage-login-flow-heading();
    }

    .stages {
      .stages-login-flow();

      .main-container {
        .header-title {
          font-size: 1.125rem;
          font-weight: 500;
          line-height: 1.4rem;
          text-align: left;
          color: #90919b;
          margin: 1rem 0;
        }

        .password-container {
          margin-top: 2rem;
          min-height: 13rem;

          .header-display {
            font-size: 20px;
            font-weight: 500;
            text-align: left;
            color: @color-Shark;
            margin: 0;
          }

          .login-info-text {
            margin-top: 10px;
            font-size: 14px;
            font-weight: 300;
            text-align: left;
            color: #90919b;
          }
        }

        .register-input {
          width: 100%;
          border-bottom: 0.5px solid @color-AthensGray;
          height: 50px;
        }

        .allowance-search-container {
          display: flex;
          justify-content: space-between;
          margin-top: 1rem;
          margin-bottom: 10px;

          .Allowance-input-field {
            // background-color: #f7f7fa;
            background-color: white;
            width: 100%;
            border: 1px solid #9898a5;
            border-radius: 5px;
          }

          .Allowance-input-field:hover,
          .Allowance-input-field:focus {
            // background-color: #f7f7fa;
            border: 1px solid #405cd2;
          }
        }

        .Employee-Upload-Status {
          width: 100%;

          p {
            font-size: 24px;
            font-weight: 400;
            line-height: 1.83;
            text-align: left;
            color: @color-Shark;
            margin: 0;
          }

          .response-data {
            width: 100%;
            .display-center-content-space-between();
            padding: 0 10px;

            p {
              font-size: 15px;
              font-weight: 300;
            }
          }

          .success {
            p {
              color: green;
              font-weight: 300;
            }
          }

          .fail {
            p {
              color: red;
              font-weight: 300;
            }
          }
        }
      }

      .submit-container-password {
        margin-right: 5rem;
        display: flex;
        justify-content: flex-start;

        .submit-button {
          .submit-login-flow();
        }
      }

      .submit-container {
        margin: 12px 0;
        display: flex;
        justify-content: flex-start;

        .skip-button {
          .skip-login-flow();
          margin-left: 20px;
          box-shadow: none;
        }

        .submit-org {
          .submit-login-flow();
        }
      }

      .structure-container {
        .structure-title {
          font-size: 1.125rem;
          font-weight: 500;
          text-align: left;
          color: #90919b;
        }

        .structure-types {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .structure-types-container:hover {
            border: solid 1px #9ea0a8;
          }

          #active {
            border: solid 1px #405cd2;
            background-color: #f8fbff;

            .structure-content {
              .structure-types-heading {

                color: #405cd2;
              }

              .structure-types-description {

                color: #405cd2;
              }
            }
          }

          .structure-types-container {
            width: 100%;
            max-width: 50rem;
            min-height: 8rem;
            padding: 25px;
            margin: 24px 0 20px;
            display: flex;
            border-radius: 10px;
            justify-content: center;
            border: solid 1px #dcdfe8;
            cursor: pointer;

            @media (max-width: 980px) {
              width: 100% !important;
            }

            .structure-logo,
            .structure-logo2,
            .structure-logo3 {
              padding: 5px 7px;
              border-radius: 50%;
              background: #232326;
              max-height: 35px;
              margin-top: 0.3rem;
            }

            .structure-logo2,
            .structure-logo3 {
              max-height: 34px !important;
              padding: 5px 7px 7px 7px !important;
            }

            .structure-content {
              padding-left: 1.5rem;

              .structure-types-heading {
                margin-bottom: 10px;
                font-size: 1.25rem;
                font-weight: 700;
                text-align: left;
                color: #232326;
              }

              .structure-types-description {
                font-size: 1.125rem;
                font-weight: 500;
                text-align: left;
                color: #90919b;
              }
            }
          }
        }
      }

      .configuration-container {
        .info-para {
          width: 100%;
          max-width: 35rem;
          margin: 40px 0;
          background-color: #f8fbff;
          padding: 0.5rem;
          border-radius: 5px;

          p {
            font-weight: 500;
            font-size: 14px;
            text-align: left;
            color: #405cd2;
            margin-bottom: 5px;
          }
        }

        .fieldQuestionsHeading {
          font-size: 16px;
          font-weight: 500;
          text-align: left;
          color: black;
          margin-bottom: 15px;
        }

        .configuration-header-display {
          font-size: 1.125rem;
          font-weight: 600;
          text-align: left;
          color: #90919b;
        }

        .radio-group {
          width: 100%;
          margin: 40px 0;

          p {
            font-size: 14px;
            font-weight: 300;
            text-align: left;
            color: #9898a5;
            margin-bottom: 5px;

            &.fieldQuestionsHeading {
              font-size: 16px;
              font-weight: 600;
              text-align: left;
              color: black;
              margin-bottom: 5px;
            }
          }

          .configuration-input-field {
            width: 280px;
            border: none;
            background-color: @color-White;
            border-bottom: 1px solid @color-AthensGray;
            box-shadow: none;
            text-align: left;
            color: @color-Shark;
            font-weight: 500;
            font-size: 15px;
            padding: 2px;
          }
        }
      }
    }
  }
}

.allowance-modal {
  padding: 10px;
  margin-bottom: 6rem;
  border-radius: 10px;

  .modal-header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .modal-section-heading {
      color: #151b30;
      font-size: 20px;
      font-weight: 600;
    }

    .modal-close {
      display: flex;
    }
  }
}