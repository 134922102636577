.display-container {
  min-height: 100vh;
  width: 100%;

  .sider {
    position: sticky;
    background: #202741;
    left: 0;
    top: 75px;
    z-index: 50;
  }

  .site-layout {
    .site-layout-header {
      background: #f0f2f5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 27px;
      border: 1px solid #d5d6df;

      .header-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .logout-form {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: space-around;

        .name-container {
          margin: 0 10px;

          @media (max-width: 600px) {
            display: none;
          }
        }
      }
    }
  }
}

.sidebar-icon {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

aside.ant-layout-sider.ant-layout-sider-light {
  min-width: 16rem !important;
  max-width: 16rem !important;
  width: 16rem !important;
  position: sticky;
}

ul.ant-menu.ant-menu-light.sider.ant-menu-root.ant-menu-inline {
  color: white;
  margin-top: 2.5rem;
}

.ant-menu-item a {
  color: #aeafb8;
  font-size: 0.9rem;

  &:hover {
    font-size: 0.9rem;
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #405cd2;
  border: none;
  display: flex;
  align-items: center;

  a {
    color: #fff !important;
  }

  .navigation__menu-label {
    color: #fff !important;
    margin-top: 2px;
  }

}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 0px solid transparent;
}

.ant-menu-inline {
  width: calc(100% + 1px);
}

li.ant-menu-item.ant-menu-item-selected {
  width: 100%;
}

li.ant-menu-item.navigation__menu-item {
  text-overflow: unset;
}

.ant-menu-item-selected a:hover {
  color: white;
}

.first-load {
  background-color: #405cd2;

  a {
    color: white;
  }
}

.ant-menu-item a:hover {
  color: #fff;
}

.ant-table-tbody>tr>td {
  font-weight: 500;
}

.navigation {
  position: fixed;
  height: 100vh;
  width: 16rem;
  overflow: hidden;
  background-color: #202741;

  &__first-menu {
    float: left;
    height: 100%;
    width: 70px;
    background: #151b30;
    transition: 0.5s;
    //overflow-x: hidden;
  }

  &__logo {
    height: 5rem;
    position: sticky;
    display: flex;
    padding: 0 0.65rem;
    top: 0;
    align-items: center;
  }

  &__colapsible {
    text-decoration: none;
    color: white;
    font-size: 1.1rem;
    cursor: pointer;
    transition: 0.5s;
    display: flex;
    padding: 13px 1rem;
    align-items: center;

    //margin: 10px 0;
    img {
      height: 15px;
      width: 15px;
    }

    ~.tooltipdiv {
      position: relative;

      .tooltip {
        visibility: hidden;
        z-index: 2;
        position: absolute;
        left: 3rem;
        top: -3rem;
        line-height: 1;
        font-size: 0.7rem;
        background: black;
        padding: 2px 7px;
        color: white;
        border-radius: 2px;
      }
    }

    &:hover {
      background-color: #2e3343;

      ~.tooltipdiv {
        .tooltip {
          visibility: visible;
        }
      }
    }
  }

  &__menu {
    .ant-menu {
      background-color: #151b30 !important;
      border-right: 0 !important;
      width: 100% !important;
      //overflow: hidden;
    }
  }

  &__menu-item {
    height: 45px !important;
    margin: 0 !important;

    ~.tooltipdiv {
      position: relative;

      .tooltip {
        visibility: hidden;
        z-index: 2;
        position: absolute;
        left: 3rem;
        top: -3rem;
        line-height: 1;
        font-size: 0.7rem;
        background: black;
        padding: 10px 7px;
        color: white;
        border-radius: 2px;
        //transition: 0.5s;
      }
    }

    &:hover {
      background-color: #2e3343;

      +.tooltipdiv {
        .tooltip {
          visibility: visible;
        }
      }
    }
  }

  &__menu-image {
    height: 15px;
    width: 15px;
  }

  &__menu-label {
    margin-left: 20px;
    color: #aeafb8;
    font-size: 0.8rem !important;
    font-weight: 700 !important;
  }

  &__second-menu {
    float: right;
    background-color: #202741;
    height: 100%;
    transition: 0.5s;
  }

  &__second-menu-heading {
    height: 5rem;
    position: sticky;
    display: flex;
    padding: 0 1rem;
    top: 0;
    align-items: center;

    span {
      margin-left: 0.8rem;
      color: #fff;
    }
  }

  &__menu-second {
    .ant-menu {
      background-color: #202741;
      border: 0px;

      .menu-content {
        color: #aeafb8;
        font-size: 0.8rem !important;
        font-weight: 700 !important;
      }
    }
  }
}

// ----------------------loginflow---------------------
.ant-layout {
  .constantSider {
    .moolLogo {
      position: absolute;
      top: 2rem;
      width: 6rem;
      left: 2.25rem;
    }

    min-width: 35vw !important;
    max-width: 35vw !important;
    height: 100vh;
    position: fixed !important;
    background-color: #f8fbff !important;
    ;

    @media (max-width: 980px) {
      display: none;
    }
  }

  .stage-container {
    margin-left: 35vw !important;

    @media (max-width: 1100px) {
      margin-left: 35vw !important;
    }

    @media (max-width: 980px) {
      margin-left: 0 !important;
    }
  }
}

.validationContainer {
  display: none;

  &.active {
    display: block;
  }

  .passwordstrengthcontainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;

    @media (max-width: 767px) {
      width: 20rem !important;
    }

    div {
      margin-right: 10px;
      transition: all .25s ease-in-out 0s;
      height: 4px;
      width: 90px;
      border-radius: 7px;

      &.defaultStrength {
        background: #c7c7d3;
      }

      &.poorStrength {
        background: #F05050;
      }

      &.mediumStrength {
        background: #ffb900;
      }

      &.strongStrength {
        background: rgb(2, 146, 2);
      }
    }
  }

  .strength_message {
    margin: 5px 0;

    h4 {
      transition: 0.2s all ease-in;

      &.poor {
        color: #F05050;
      }

      &.medium {
        color: #ffb900;
      }

      &.strong {
        color: rgb(2, 146, 2);
      }
    }
  }

  .validation_check {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;

    @media (max-width: 767px) {
      width: 20rem !important;
    }

    .validation {
      min-width: 200px;
      margin-right: 30px;
      max-width: 300px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 500px) {
        margin-right: -15px !important;
      }

      div {
        width: 8px;
        height: 8px;
        border-radius: 50%;

        &.active {
          background: #17c173;
        }

        &.inactive {
          background: #9ea0a8;
        }
      }

      p {
        margin-bottom: 0 !important;
        margin-left: 6px;
        font-size: 14px;
        font-weight: 600;

        &.active_validation_msg {
          color: #5e5f62;
        }

        &.inactive_validation_msg {
          color: #9ea0a8;
        }
      }
    }
  }
}


.pfcard-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .ant-card.pfcard {
    border: 1px solid #9ea0a8;
    border-radius: 10px !important;
    width: 18rem !important;

    @media (max-width: 767px) {
      width: 100% !important;
    }

    &:hover {
      cursor: pointer;
    }

    &:first-child {
      margin-right: 20px;

      @media (max-width: 767px) {
        margin: 10px 0 20px 0;
      }
    }

    .ant-card-head {
      border-bottom: 0px;
      padding: 0 17px !important;
    }

    .ant-card-body {
      padding: 0px 17px 13px 17px !important;
    }

    &.active {
      border: 1px solid #405cd2 !important;
      color: #405cd2 !important;
      background: #f8fbff !important;

      .ant-card-head {
        color: #405cd2 !important;
      }

      .ant-card-body p {
        color: #405cd2 !important;
      }
    }
  }
}

.radio-group.employeecontributionrate {
  position: relative;

  @media (max-width: 767px) {
    margin: -20px 0 40px 0 !important;
  }

  &:before {
    content: '';
    height: 40px;
    width: 40px;
    background-color: #f8fbff;
    position: absolute;
    left: calc(37.5rem - 20%);
    top: -16px;
    transform: rotate(45deg);

    @media (max-width: 767px) {
      left: calc(100% - 20%);
    }
  }

  .employeecontributionrate-cont {
    border-radius: 10px;
    background: #f8fbff;
    width: 37rem;

    @media (max-width: 767px) {
      width: 100%;
    }

    padding: 20px 10px;
  }
}

.orgAddressContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 35rem;
  margin-bottom: -20px;

  .fieldContainer {
    width: 30%;
  }
}

.navigation__second-menu {
  overflow-y: auto;

}

.navigation__second-menu-heading {
  position: relative;
}
@primary-color: #405CD2;