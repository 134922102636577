.upload-files-table {
  font-size: 500;
  font-size: 16px;
}

.files-status-container {
  .files-status-uploaded {
    color: @color-Mariner;
  }
  .files-status-pending {
    color: @color-OrangePeel;
  }
}

.upload-files-container {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 30px;

  .upload-files-body {
    padding: 30px;
    min-height: 75vh;
    margin-bottom: 50px;
  }
}

.employee-upload-files-container {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 30px;

  .deduction-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .year-container {
      display: flex;
      align-items: center;
    }
  }
  .employee-upload-files-header {
    padding: 20px 30px;
    padding-bottom: 10px;
    border-bottom: @color-Mischka 1.5px solid;

    .header-left-side {
      font-size: 16px;
      font-weight: 500;

      .arrow-left-button {
        font-size: 20px;
        padding: 2px 8px;
        margin-right: 20px;
      }

      .avatar {
        border-radius: 50%;
        margin-right: 12px;
      }

      .employee-display-name {
        margin-right: 20px;
      }

      .employee-id {
        background-color: @color-titanWhite;
        padding: 4px 12px;
      }
    }
  }
  .employee-upload-files-body {
    padding: 1rem;
    .employee-upload-files-container {
      width: 60%;
      margin: 0px 8px;
      .employee-upload-heading {
        font-size: 20px;
        font-weight: 600;
      }
      .employee-upload-files-category-holder {
        background-color: #f6f6fb;
        border-radius: 5px;
        margin: 16px 0px;
        padding: 16px;
        font-size: 1rem;
        font-weight: 600;

        .employee-upload-files-sub-category-container {
          margin-bottom: 16px;
          .employee-upload-files-sub-category-heading {
            font-size: 14px;
            font-weight: 500;
          }

          .document-container {
            cursor: pointer;
            padding: 10px;
            margin: 10px 0px;
            .document-details-container {
              .file-logo-container {
                margin-right: 16px;
              }
              .file-details {
                font-size: 14px;
                font-weight: 400;
                .file-name {
                  color: @color-MidGray;
                }
                .file-created-at {
                  color: #505057;
                }
              }
            }
          }
        }
      }
    }
  }
}

.upload-files-header {
  padding: 20px 30px;
  padding-bottom: 10px;
  border-bottom: @color-Mischka 0.5px solid;

  .header-left-side {
    height: 40px;

    .arrow-left-button {
      font-size: 20px;
      padding: 2px 8px;
      margin-right: 20px;
    }

    .display-name {
      margin-right: 20px;
      font-size: 17.5px;
      font-weight: 800;
    }
  }
}
