.float-label {
    position: relative;
    margin-bottom: 15px;
    width: 100%;
}

.label {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.81;
    letter-spacing: normal;
    text-align: left;
    color: #9898a5;
    position: absolute;
    pointer-events: none;
    left: 2px;
    top: 14px;
    transition: 0.2s ease all;
}

.label-float {
    top: -5px;
    font-size: 10px;
}
@primary-color: #405CD2;