

.summary-container {
  height: calc(100vh - 250px);
  overflow-y: scroll;
  padding: 24px;
  width: 600px;

  .summary-heading {
    font-size: 24px;
    font-weight: 400;
  }

  .summary-subheading {
    font-size: 14px;
    color: @color-Jumbo;
  }

  .investment-list-item-conatiner {
    .display-center-content-space-between();
    margin: 32px 0px;

    .investment-list-item-name {
      font-weight: 500;
      font-size: 18px;
    }

    .investment-list-item-type {
      color: @color-FrenchGray;
    }

    .investment-list-item-price {
      font-weight: 500;
    }
  }

  .total-price {
    text-align: right;
    margin-top: 36px;
    font-size: 18px;
    font-weight: 600;

    .total-price-heading {
      margin-right: 16px;
      color: @color-Manatee;
    }
  }
}

.document-list {
  margin: 10px 0;
  .document-container {
    .display-center-content-space-between();
    margin: 16px 0;
    .file-typeHeader {
      width: 50px;
      height: 50px;
      margin: 0 10px 0;
      font-weight: 400;
      border-radius: 2px;
      background-color: @color-FairPink;
      color: @color-MediumCarmine;
      .display-align-center-justify-content-center();
    }
    span {
      text-align: left;
      font-weight: 500;
      color: @color-MidGray;
    }
    .date-contianer {
      line-height: 2.14;
      font-size: 12px;
      letter-spacing: normal;
      text-align: left;
      color: @color-FrenchGray;
    }
    .verifiection-text {
      letter-spacing: normal;
      font-size: 13px;
      text-align: left;
      float: right;
    }
    .verified {
      color: @color-Shamrock;
    }
    .not-verified {
      color: @color-OrangePeel;
    }
  }
}

.document-group {
  .document-header {
    .display-center-content-space-between();
    padding: 10px;
    padding-right: 0px;
    .document-header-button-and-name-container {
      .display-align-center();
      .document-action-container {
        .display-center-content-space-between();
      }
    }
    p {
      margin-left: 10px;
      font-size: 15px;
      font-weight: 500;
    }
    .allowance-amount {
      font-weight: 500;
      color: @color-OrangePeel;
    }
  }
}

.hra-container,
.other-allowances-container,
.tax-projection-container,
.manage-allowances-container {
  padding: 8px 24px;
}
