allowance-primary-red {
  width: 900px;
}

.allowance-container {
  background-color: #ffffff;
  padding: 30px;
  margin: 20px;
  border-radius: 30px;
  height: 900px;
}

.allowance-heading-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.allowance-heading-div {
  display: flex;
  flex-direction: column;
}

.allowance-searchbar-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-allowance {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.allowance-radio-div {
  display: flex;
  align-self: flex-start;
  margin: 0 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.allowance-radio {
  display: flex;
}

.checkbox-allowance-div {
  display: flex;
  align-self: flex-start;
  margin: 0 30px;
  gap: 2px;
}

.save-allowance-div {
  display: flex;
  justify-self: flex-end;
  align-self: flex-start;
  margin: 0 30px;
}

.limit-amount-box {
  padding: 20px;
  margin: 0 1rem 1rem 0px;
  border-radius: 5px;
}
