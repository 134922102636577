.color-green {
  color: @color-caGreen;
}

.btn-green {
  background-color: #e4fff8;
  padding: 8px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.5px;
}

.payroll-card {
  height: 150px;
  padding: 15px 15.5px 11.5px 15px;
  border-radius: 8px;
  border: solid 0.5px #d4dcff;
  background-color: #e8ecff;
  margin: 0 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.payroll-card * {
  color: #718bff;
}
.payroll-card.active * {
  color: white;
}
.payroll-card.active {
  background-color: #405cd2;
}

.next,
.prev {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  padding: 5px;
  background-color: #405cd2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: larger;
  font-weight: 600;
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translate(-50%, -50%);
}
.prev {
  left: 5%;
  z-index: 1111;
}
.content-relative {
  position: relative;
}

// .slick-slide{
//   width: 250px !important;
// }

// .slick-track{
//   transform: translate3d(-250px, 0px, 0px) ;
// }

.swiper-button-next,
.swiper-button-prev {
  padding: 20px;
  top: 50% !important;
  transform: translate(-50%, -50%);
  margin: 0;
  height: 100% !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: #405cd2;
  opacity: 0.9;
}

.swiper-button-prev {
  left: 30px !important;
}
.swiper-button-next {
  right: -15px !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 25px !important;
  color: white !important;
}

.payroll-process .separator {
  width: 2px;
  background-color: gray;
  height: 110px;
  opacity: 0.7;
}
