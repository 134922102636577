.first-login-container {
  .first-login-flow-container();

  .stage-container {
    .stage-login-flow-container();
    .display-flex-column-justify-content-space-around();

    .stage-heading {
      .stage-login-flow-heading();
    }
  }
}

.stages {
  .stages-login-flow();

  .main-container {
    .ant-upload-drag {
      height: 50%;
    }

    .header-title {
      font-size: 14px;
      font-weight: 300;
      line-height: 0.83;
      text-align: left;
      color: #90919b;
    }

    .password-container {
      margin-top: 3rem;
      min-height: 13rem;

      .employee-header-display {
        font-size: 20px;
        font-weight: 500;
        text-align: left;
        color: @color-Shark;
        margin-bottom: 25px;
      }

      .login-info-text {
        font-size: 14px;
        font-weight: 300;
        text-align: left;
        color: #90919b;
      }
    }

    .payslip-title {
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: #90919b;
    }

    .register-input {
      width: 100%;
      border-bottom: 1px solid @color-AthensGray;
      height: 60px;
    }

    .header-display {
      .display-center-content-space-between();

      .header-title {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.25;
        text-align: left;
        color: #90919b;
      }

      .search-feild {
        width: 250px;
        background-color: @color-WildSand;
      }
    }

    .allowances-container {
      max-height: 340px;
      height: 340px;
      overflow-y: auto;
      width: 100%;
      margin-top: 10px;
      &.employee {
        max-height: 570px;
        height: 570px;
        padding-right: 5px;
      }

      &::-webkit-scrollbar {
        width: 0.5rem;
        background-color: transparent;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: transparent;
      }

      &:hover {
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
        }
      }

      .single-allowance {
        .display-center-content-space-between();
        align-items: center;
        border: none;
        // border-bottom: solid 0.1px @color-AthensGray;
        padding: 5px;
        padding-left: 0;
        padding-bottom: 20px;
        height: 50px;
        margin-top: 1rem;
        &.house-rent{
          margin: 2rem 0;
        }
        // border-bottom: 1px solid #dcdfe8 !important;
        p {
          font-size: 15px;
          font-weight: normal;
          text-align: left;
          color: @color-ManateeLite;
          margin: 0;
        }
      }

      .dragger-container {
        height: 65%;
      }

      .eula-info {
        font-stretch: normal;
        font-weight: normal;
        color: @color-Shark;
        font-size: 13px;
        font-weight: bold;
        text-align: justify;
        margin: 0;
      }

      .regime-value {
        .display-center-content-space-between();
        width: 100%;
        padding: 0 15px 0 0;
        margin: 10px 0;

        span {
          font-size: 20px;
        }
      }
    }
  }

  .terms-and-conditions-container {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
    margin-top: 20px;
  }

  .ctc-container-initial {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    margin-top: 1rem;

    .ctc-amount {
      margin: 0 0 12px 5px;
      display: flex;
      align-items: center;

      .ctc-index {
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        color: #90919b;
      }

      .ctc-actual-amount {
        margin-left: 3px;
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        color: #151b30;
      }
    }

    .inhand-container {
      .display-flex-column-justify-content-center();
      align-items: flex-end;

      p {
        font-size: 18px;
        .font-style-right();
        color: @color-Shark;
        margin: 0;
      }

      span {
        font-size: 14px;
        .font-style-right();
        color: @color-FrenchGray;
      }
    }
  }

  .submit-container-regime {
    .display-align-center-justify-content-space-around();

    .skip-button {
      .skip-login-flow();
      box-shadow: none;
    }
  }

  .submit-container-password {
    margin: 10px 0;
    .display-flex-justify-content-flex-end();

    .submit-button {
      .submit-login-flow();
    }
  }

  .submit-container {
    margin: 10px 0;
    .display-flex-justify-content-flex-end();

    .skip-button {
      .skip-login-flow();
      box-shadow: none;
      margin-left: 20px;
    }

    .submit-button {
      .submit-login-flow();
    }
  }
}

.eulabox {
  width: 100%;
  padding: 10px 0px;
  max-height: 570px;
  overflow: auto;
  color: #90919b;
  font-size: 1.125rem;
  &::-webkit-scrollbar {
    width: 0.5rem;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }
  }
}