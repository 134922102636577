.site-layout-header {

  .header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-top-name {
      margin: 0px;
      color: #232326;
      font-size: 24px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      margin-left: 10px;

      @media (max-width: 600px) {
        font-size: 20px;
      }
    }

    // .header-top-icon {
    //   height: 20px;
    //   width: 20px;
    //   object-fit: cover;
    // }
  }

  .logout-form {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .user-logo {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin: 0px;
      object-fit: scale-down;
    }

    .dropdown-logo-container {
      padding: 0px;
      border: none;
      background-color: #f0f2f5;
      height: 100%;
    }

    .name-container {
      margin: 0;
      margin-right: 24px;
      color: #73737b;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.mool-coins-dropdown-anchor:active,
.mool-coins-dropdown-anchor:hover,
.mool-coins-dropdown-anchor {
  color: #f4b844;
  margin-right: 40px;
  font-size: 16px;
  font-weight: 500;

  .mool-coins-dropdown-arrow-icon {
    margin-left: 16px;
  }
}

.mool-coins-icon {
  margin-right: 8px;
}

.separation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .separation-header {
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    color: #232326;
  }
}
@primary-color: #405CD2;