.completion-message-container {
  min-height: 100vh;
  width: 100%;
  background: url("../../assets/orgainsationOnboardingFinalMessageBackground.svg");
  .img-background();
  .display-align-center-justify-content-center();

  .completion-message-container-message-container {
    background-color: @color-White;
    width: 500px;
    border-radius: 10px;
    box-shadow: 0 1.5px 30px 0 @color-FineBlack;
    padding: 45px;

    .completion-message-heading {
      font-size: 40px;
      font-weight: 800;
      line-height: 55px;
    }

    .completion-message-para {
      color: @color-ManateeLite;
      margin-top: 24px;
      font-size: 15px;
    }

    .completion-message-button-container {
      margin-top: 150px;
      text-align: right;

      .completion-message-button {
        color: @color-White;
        background-color: @color-Shamrock;
        border-radius: 10px;
        .border();
        font-size: 16px;
        padding: 16px;
      }
    }
  }
}
