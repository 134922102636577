.tax-projection-container {
  .tax-projection-header {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0px;

    .tax-projection-user-name {
      font-size: 1.25rem;
      font-weight: 600;
    }

    .tax-projection-user-text {
      font-size: 1rem;
      color: @color-Jumbo;
    }
  }

  .info-container {
    margin-top: 2rem;
    .info-data {
      font-size: 0.75rem;
      margin-bottom: 0.75rem;
      font-weight: 400;
      .info-heading {
        color: @color-Mariner;
      }
    }
  }
}
