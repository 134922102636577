.attachDiv {
  width: 100%;
}

.attachDiv {
  height: 70px;
}

.navbar {
  border-bottom: 1px solid #80808040;
}

.main {
  display: flex;
  width: 100%;
}

.signoutDiv {
  text-align: right;
  height: 70px;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 30px;
}

.contentDiv {
  padding: 3rem;
}

.textDiv {
  padding: 2% 20% 0 0;
}

.draggerDiv {
  padding: 2% 60% 0 0;
}

.btnDiv {
  padding-top: 2%;
  margin-left: -5px;
}

.textP {
  padding-top: 2%;
}

@primary-color: #405CD2;