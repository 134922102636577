.halfNavbar {
  border-bottom: 1px solid #80808040;
  display: flex;
  width: 100%;
}

.main {
  display: flex;
  width: 100%;
}

.logoDiv {
  width: 90%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 30px;
}

.signoutDivi {
  text-align: right;
  width: 10%;
  height: 7vh;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 30px;
}

.successDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.orderSuccess {
  align-items: center;
  justify-content: center;
}

.footerDiv {
  width: 100vw;
  background-color: #f2f2ff;
  display: flex;
  padding: 1rem;
}

.centerDiv {
  display: "flex";
  width: "100vw";
  height: "86vh";
  align-items: center;
  justify-content: center;
}

.orderSuccess {
  width: 19rem;
  padding: 1rem 0;
}

.bold {
  font-weight: bold;
}


.para {
  width: 28rem;
  padding: 10px 0;
}

@media screen and (max-width:768px) {
  .para{
    width: auto;
  }
}
@primary-color: #405CD2;