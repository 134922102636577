.terms-and-condition-modal {
  min-width: 60vw;

  .terms-and-condition-modal-header-container {
    margin: 16px 30px;
    .display-center-content-space-between();
    .terms-and-condition-modal-header {
      font-size: 32px;
      font-weight: 800;
    }
  }

  .terms-and-condition-modal-body {
    margin: 0px 48px;

    .last-p {
      font-size: 18px;
      text-align: center;
      margin-bottom: 32px;
    }
  }

  .intro-p {
    font-size: 16px;
    margin-bottom: 32px;
    text-align: justify;
  }

  .terms-and-conditions-list {
    padding: 0px;
    .li-items {
      margin: 32px 0px;
      font-size: 16px;
      text-align: justify;

      .list-heading {
        font-weight: 700;
        text-decoration: underline;
      }

      .list-p {
        display: inline;
        text-align: justify;
      }
    }
  }
}
