.file-logos-type-background {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border-radius: 5px;
  font-weight: 800;
  font-size: 0.7rem;
  color: #fff;

  .file-logos-type-text {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.file-logos-type-1,
.file-logos-type-pdf {
  background-color: #f26b6b;
}

.file-logos-type-2,
.file-logos-type-3,
.file-logos-type-jpg,
.file-logos-type-svg {
  background-color: #6640d2;
}

.file-logos-type-5,
.file-logos-type-doc {
  background-color: #005397;
}

.file-logos-type-6,
.file-logos-type-xls {
  background-color: #1d6f42;
}

.file-logos-type-7,
.file-logos-type-4,
.file-logos-type-txt,
.file-logos-type-png {
  background-color: #00a4ef;
}

@primary-color: #405CD2;