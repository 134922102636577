@import url("../styles/typography.less");
.zero-state {
  z-index: 100;
  margin-left: 164px;
  margin-right: 164px;
  position: relative;
  .party-popper {
    height: 2.7rem;
    width: 2.7rem;
    margin-bottom: 1rem;
    pointer-events: none;
  }
  .top-bar {
    padding: 0.8rem 2.5rem;
    border-bottom: 1px solid #d5d6df;
    img {
      margin-right: 2rem;
    }
    .title {
      font-weight: 700;
      font-size: 1.5rem;
      img {
        margin-bottom: 2px;
        margin-right: 0.6rem;
      }
    }
    .right-menu {
      display: flex;
      align-items: center;
      .wallet {
        margin-left: 1rem;
        margin-right: 0.5rem;
      }
      .wallet + p {
        display: inline;
        margin-right: 2rem;
        color: #000;
        font-weight: 700;
        font-size: 1.1rem;
        span {
          color: #f4b844;
          margin-right: 0.5rem;
        }
      }
      .img-container {
        display: inline;
        align-items: center;
        justify-content: center;
        img {
          margin-right: -1.5rem;
        }
      }
    }
  }
  .head {
    width: 90%;
    margin: 0 ;
    border-bottom: 1px solid #d5d6df;

    h1 {
      .h4-bold-36();
      padding-top: 2rem;
      font-weight: 700;
    }
    p {
      .subtitle1-med-16();
      color: #5f5f65;
      margin-bottom: 1.5rem;
      margin-left: 0.2rem;
    }
  }
  .grid {
    width: 90%;
    margin: 0 auto;
    margin-top: 2rem;
    display: flex;
    .left {
      flex: 0.8;
      .top {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        img {
          margin-right: 2rem;
        }
        h3 {
          .h6-bold-18();
        }
        .box {
          justify-content: start;
          width: 60%;
          margin-top: 1rem;
          background-color: #fff;
          padding: 1rem 1.5rem;
          padding-right: 3rem;
          border-radius: 10px;
          cursor: pointer;

          .img-container {
            margin-right: 2rem;
            background-color: #e8e9ee;
            padding: 0.6rem;
            border-radius: 2px;
            img {
              margin: 0 auto;
            }
          }
          .details {
            span {
              font-size: 0.6rem;
              font-weight: 600;
              color: #90919b;
            }
            .duration {
              span {
                margin-left: -2rem;
                font-size: 0.9rem;
              }
            }
          }
        }
        // &:hover {
        //   .box {
        //     .img-container {
        //       background-color: #eff2ff;
        //     }
        //   }
        // }
      }
      .bottom {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        h3 {
          color: #232326;
          font-weight: 700;
        }
        .box {
          width: 43%;
          padding: 1.1rem 1.5rem;
          padding-right: 3rem;
          margin-top: 1rem;
          margin-right: 2rem;
          background-color: #fff;
          border-radius: 10px;
          justify-content: start;
          cursor: pointer;
          .img-container {
            background-color: #e8e9ee;
            padding: 0.6rem;
            border-radius: 2px;
            margin-right: 1rem;
            img {
              margin: 0 auto;
            }
          }
        }
      }
    }
    .right {
      flex: 1.2;
      background-color: #fff;
      .top {
        padding: 1rem 1rem;
        color: #fff;
        background-color: #405cd2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px 8px 0 0;
        .title {
          h3 {
            color: #fff;
            .h6-bold-18();
          }
          p {
            .subtitle2-med-14();
          }
        }
        p {
          font-size: 0.7rem;
        }
      }
      .bottom {
        .entry {
          display: flex;
          align-items: center;
          margin: 0rem 0 0rem 1rem;
          .bigger-container {
            width: 3rem;
            .img-container {
              width: 50%;
              height: 1.45rem;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              img {
                width: 1rem;
              }
            }
          }
          h3 {
            font-size: 1rem;
            flex: 12;
          }
          .btn {
            margin-right: 1rem;
            padding: 0.5rem;
            border-radius: 5px;
            font-size: 0.875rem;
            font-weight: 500;
            color: #0fa84f;
          }
        }
      }
    }
  }
  .ant-progress-text {
    color: white !important;
    padding: 0 0.5rem;
    font-size: 0.8rem;
  }
}
.box-container {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  margin-left: 70px;
  .box-box {
    display: flex;
    flex-direction: row;
    border: solid 0.5px #e8e8ed;
    width: 240px;
    height: 70px;
    padding: 50px 287.5px 50px 30px;
    border-radius: 7px;
    align-items: center;
    margin: 10px;
    h4 {
      font-size: medium;
      font-weight: 700;
      margin-left: 2rem;
    }
    .big-box {
        background-color: #e8e9ee;
        border-radius: 4px;
        padding: 1rem
      }
  }
  
}

.blue-ellipse {
  position: absolute;
  height: 27rem;
  width: 27rem;
  top: -.1rem;
  right: -15rem;
  z-index: -10;
}

.green-ellipse {
  position: absolute;
  height: 12rem;
  width: 12rem;
  bottom: 20rem;
  left: -15rem;
  z-index: -10;
}
.red-ellipse {
  position: absolute;
  border-radius: 100%;
  background-color: #ff5678;
  padding: 2rem;
  top: 15rem;
  left: -2rem;
  z-index: -10;
}
.yellow-rect {
  position: absolute;
  background-color: #ffb900;
  height: 4.6rem;
  width: 3.35rem;
  padding: 0rem;
  top: .2rem;
  left: -0.1rem;
  z-index: -10;
}
@primary-color: #405CD2;