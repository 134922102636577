.empty-array-error {
  margin: 32px 0px;
  font-size: 19px;
  font-weight: 500;
  text-align: center;
  color: @color-FuelYellow;
}

.employee-add-list-container {
  margin-top: 16px;
}

.employee-exists-list-container {
  overflow-x: auto;
  min-height: 600px;
}

.main-content-wrapper {
  overflow: hidden;
}

.fail-reason {
  color: @color-Froly;
  font-size: 16px;
}

.upload-status-text {
  font-size: 18px;
  color: @color-MidGray;
  font-weight: 500;
  margin-right: 18px;
}

.error-row {
  background-color: #ffeeee;
}

.padding-20 {
  padding: 0px 10px;
}
.text-align-left {
  text-align: left;
}
.list-row-item {
  width: 300px;
  .text-align-left();
}

.list-row-error {
  width: 100px;
  .text-align-left();
}

.list-row-name {
  width: 300px;
  .padding-20();
  .text-align-left();
}

.list-row-employee-id {
  width: 200px;
  .text-align-left();
  .padding-20();
}

.list-row-pan {
  width: 250px;
  .text-align-left();
  .padding-20();
}

.list-row-email {
  width: 500px;
  .padding-20();
  .text-align-left();
}

.list-row-phone {
  width: 250px;
  .padding-20();
  .text-align-left();
}
.list-row-doj {
  width: 300px;
  .padding-20();
  .text-align-left();
}
.list-row-designation {
  .list-row-item();
  .padding-20();
  .text-align-left();
}
.list-row-department {
  .list-row-item();
  .padding-20();
  .text-align-left();
}
.list-row-status {
  .list-row-item();
  .padding-20();
  .text-align-left();
}
.list-row-location {
  .list-row-item();
  .padding-20();
  .text-align-left();
}
.list-row-ctc {
  width: 200px;
  .padding-20();
  .text-align-left();
}
.list-row-vp {
  width: 100px;
  padding-left: 20px;
  .text-align-left();
}
.list-row-email-associated {
  width: 350px;
  .padding-20();
  .text-align-left();
}
.list-row-designation-associated {
  width: 250px;
  .padding-20();
  .text-align-left();
}
.list-row-error-msg {
  width: fit-content;
  .padding-20();
  .text-align-left();
}

.uploaded-data-validate-table {
  width: 3000px;
}

.list-row-container {
  overflow-x: auto;
}

.overflow-table {
  overflow-x: auto;
  border-spacing: 0px 8px;
  width: 100%;
}

td {
  margin: 10px 0px;
}
