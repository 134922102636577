.py-2 {
  padding: 2px 0px;
}
.py-4 {
  padding: 4px 0px;
}
.py-6 {
  padding: 6px 0px;
}
.py-8 {
  padding: 8px 0px;
}
.py-10 {
  padding: 10px 0px;
}
.py-12 {
  padding: 12px 0px;
}
.py-16 {
  padding: 16px 0px;
}
.py-20 {
  padding: 20px 0px;
}
.py-24 {
  padding: 24px 0px;
}
.py-30 {
  padding: 30px 0px;
}
.py-36 {
  padding: 36px 0px;
}
.px-2 {
  padding: 0px 2px;
}
.px-4 {
  padding: 0px 4px;
}
.px-6 {
  padding: 0px 6px;
}
.px-8 {
  padding: 0px 8px;
}
.px-10 {
  padding: 0px 10px;
}
.px-12 {
  padding: 0px 12px;
}
.px-16 {
  padding: 0px 16px;
}
.px-20 {
  padding: 0px 20px;
}
.px-24 {
  padding: 0px 24px;
}
.px-30 {
  padding: 0px 30px;
}
.px-36 {
  padding: 0px 36px;
}

.p-right-2 {
  padding-right: 2px;
}
.p-right-4 {
  padding-right: 4px;
}
.p-right-6 {
  padding-right: 6px;
}
.p-right-8 {
  padding-right: 8px;
}
.p-right-10 {
  padding-right: 10px;
}
.p-right-12 {
  padding-right: 12px;
}
.p-right-16 {
  padding-right: 16px;
}
.p-right-20 {
  padding-right: 20px;
}
.p-right-24 {
  padding-right: 24px;
}
.p-right-30 {
  padding-right: 30px;
}
.p-right-36 {
  padding-right: 36px;
}

.p-bottom-2 {
  padding-bottom: 2px;
}
.p-bottom-4 {
  padding-bottom: 4px;
}
.p-bottom-6 {
  padding-bottom: 6px;
}
.p-bottom-8 {
  padding-bottom: 8px;
}
.p-bottom-10 {
  padding-bottom: 10px;
}
.p-bottom-12 {
  padding-bottom: 12px;
}
.p-bottom-16 {
  padding-bottom: 16px;
}
.p-bottom-20 {
  padding-bottom: 20px;
}
.p-bottom-24 {
  padding-bottom: 24px;
}
.p-bottom-30 {
  padding-bottom: 30px;
}
.p-bottom-36 {
  padding-bottom: 36px;
}

.p-left-2 {
  padding-left: 2px;
}
.p-left-4 {
  padding-left: 4px;
}
.p-left-6 {
  padding-left: 6px;
}
.p-left-8 {
  padding-left: 8px;
}
.p-left-10 {
  padding-left: 10px;
}
.p-left-12 {
  padding-left: 12px;
}
.p-left-16 {
  padding-left: 16px;
}
.p-left-20 {
  padding-left: 20px;
}
.p-left-24 {
  padding-left: 24px;
}
.p-left-30 {
  padding-left: 30px;
}
.p-left-36 {
  padding-left: 36px;
}

.p-top-2 {
  padding-top: 2px;
}
.p-top-4 {
  padding-top: 4px;
}
.p-top-6 {
  padding-top: 6px;
}
.p-top-8 {
  padding-top: 8px;
}
.p-top-10 {
  padding-top: 10px;
}
.p-top-12 {
  padding-top: 12px;
}
.p-top-15 {
  padding-top: 15px;
}
.p-top-16 {
  padding-top: 16px;
}
.p-top-20 {
  padding-top: 20px;
}
.p-top-24 {
  padding-top: 24px;
}
.p-top-30 {
  padding-top: 30px;
}
.p-top-36 {
  padding-top: 36px;
}

.my-2 {
  margin: 2px 0px;
}
.my-4 {
  margin: 4px 0px;
}
.my-6 {
  margin: 6px 0px;
}
.my-8 {
  margin: 8px 0px;
}
.my-10 {
  margin: 10px 0px;
}
.my-12 {
  margin: 12px 0px;
}
.my-16 {
  margin: 16px 0px;
}
.my-20 {
  margin: 20px 0px;
}
.my-24 {
  margin: 24px 0px;
}
.my-30 {
  margin: 30px 0px;
}
.my-36 {
  margin: 36px 0px;
}
.mx-2 {
  margin: 0px 2px;
}
.mx-4 {
  margin: 0px 4px;
}
.mx-6 {
  margin: 0px 6px;
}
.mx-8 {
  margin: 0px 8px;
}
.mx-10 {
  margin: 0px 10px;
}
.mx-12 {
  margin: 0px 12px;
}
.mx-16 {
  margin: 0px 16px;
}
.mx-20 {
  margin: 0px 20px;
}
.mx-24 {
  margin: 0px 24px;
}
.mx-30 {
  margin: 0px 30px;
}
.mx-36 {
  margin: 0px 36px;
}

.m-right-2 {
  margin-right: 2px;
}
.m-right-4 {
  margin-right: 4px;
}
.m-right-5 {
  margin-right: 5px;
}
.m-right-6 {
  margin-right: 6px;
}
.m-right-8 {
  margin-right: 8px;
}
.m-right-10 {
  margin-right: 10px;
}
.m-right-12 {
  margin-right: 12px;
}
.m-right-16 {
  margin-right: 16px;
}
.m-right-20 {
  margin-right: 20px;
}
.m-right-24 {
  margin-right: 24px;
}
.m-right-30 {
  margin-right: 30px;
}
.m-right-36 {
  margin-right: 36px;
}

.m-bottom-2 {
  margin-bottom: 2px;
}
.m-bottom-4 {
  margin-bottom: 4px;
}
.m-bottom-5 {
  margin-bottom: 5px;
}
.m-bottom-6 {
  margin-bottom: 6px;
}
.m-bottom-8 {
  margin-bottom: 8px;
}
.m-bottom-10 {
  margin-bottom: 10px;
}
.m-bottom-12 {
  margin-bottom: 12px;
}
.m-bottom-15 {
  margin-bottom: 15px;
}
.m-bottom-16 {
  margin-bottom: 16px;
}
.m-bottom-20 {
  margin-bottom: 20px;
}
.m-bottom-24 {
  margin-bottom: 24px;
}
.m-bottom-30 {
  margin-bottom: 30px;
}
.m-bottom-36 {
  margin-bottom: 36px;
}
.m-bottom-40 {
  margin-bottom: 40px;
}

.m-left-2 {
  margin-left: 2px;
}
.m-left-4 {
  margin-left: 4px;
}
.m-left-6 {
  margin-left: 6px;
}
.m-left-8 {
  margin-left: 8px;
}
.m-left-10 {
  margin-left: 10px;
}
.m-left-12 {
  margin-left: 12px;
}
.m-left-14 {
  margin-left: 14px;
}
.m-left-15 {
  margin-left: 15px;
}
.m-left-16 {
  margin-left: 16px;
}
.m-left-20 {
  margin-left: 20px;
}
.m-left-24 {
  margin-left: 24px;
}
.m-left-30 {
  margin-left: 30px;
}
.m-left-36 {
  margin-left: 36px;
}

.m-top-2 {
  margin-top: 2px;
}
.m-top-4 {
  margin-top: 4px;
}
.m-top-6 {
  margin-top: 6px;
}
.m-top-8 {
  margin-top: 8px;
}
.m-top-10 {
  margin-top: 10px;
}
.m-top-12 {
  margin-top: 12px;
}
.m-top-16 {
  margin-top: 16px;
}
.m-top-20 {
  margin-top: 20px;
}
.m-top-24 {
  margin-top: 24px;
}
.m-top-30 {
  margin-top: 30px;
}
.m-top-36 {
  margin-top: 36px;
}

.m-2 {
  margin: 2px;
}
.m-4 {
  margin: 4px;
}
.m-6 {
  margin: 6px;
}
.m-8 {
  margin: 8px;
}
.m-10 {
  margin: 10px;
}
.m-12 {
  margin: 12px;
}
.m-16 {
  margin: 16px;
}
.m-20 {
  margin: 20px;
}
.m-24 {
  margin: 24px;
}
.m-30 {
  margin: 30px;
}
.m-36 {
  margin: 36px;
}

.p-2 {
  padding: 2px;
}
.p-4 {
  padding: 4px;
}
.p-6 {
  padding: 6px;
}
.p-8 {
  padding: 8px;
}
.p-10 {
  padding: 10px;
}
.p-12 {
  padding: 12px;
}
.p-16 {
  padding: 16px;
}
.p-20 {
  padding: 20px;
}
.p-24 {
  padding: 24px;
}
.p-30 {
  padding: 30px;
}
.p-36 {
  padding: 36px;
}

.font-weight-100 {
  font-weight: 100;
}

.font-weight-200 {
  font-weight: 200;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

.font-size-1 {
  font-size: 1px;
}

.font-size-2 {
  font-size: 2px;
}

.font-size-3 {
  font-size: 3px;
}

.font-size-4 {
  font-size: 4px;
}

.font-size-5 {
  font-size: 5px;
}

.font-size-6 {
  font-size: 6px;
}

.font-size-7 {
  font-size: 7px;
}

.font-size-8 {
  font-size: 8px;
}

.font-size-9 {
  font-size: 9px;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-26 {
  font-size: 26px;
}

.font-size-28 {
  font-size: 28px;
}

.font-size-30 {
  font-size: 30px;
}

.font-size-34 {
  font-size: 34px;
}

.font-size-38 {
  font-size: 38px;
}

.font-size-42 {
  font-size: 42px;
}

.font-size-46 {
  font-size: 46px;
}

.font-size-50 {
  font-size: 50px;
}

.font-size-54 {
  font-size: 54px;
}

.font-size-56 {
  font-size: 56px;
}

.font-size-60 {
  font-size: 60px;
}

.color-grey {
  color: @color-Manatee;
}
