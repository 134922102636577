.left-side-tabs {
  display: inline-block;
  margin: 10px 0px;
  width: 175px;
  font-size: 16px;
  text-align: left;
  font-weight: 400;
}

/* .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: @color-Mariner;
  font-weight : 400;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: @color-Mariner;
  pointer-events: none;
} */

.manage-declaration-container {
  width: 95%;
  margin: 0px auto;
  margin-top: 14px;
  background-color: @color-White;
  border-radius: 10px;
  padding: 15px;
  min-height: 85vh;

  .tabs {
    .display-content-center();
    margin: 0.3rem 3rem;

    .tab-logo {
      width: 25px;
      margin-right: 15px;
    }

    .tab-name {
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.manage-btn {
  border-radius: 10px;
  .border();
  margin-left: 20px;
  width: 100px;
}

.cancel-manage-btn,
.cancel-manage-btn:focus,
.cancel-manage-btn:active,
.cancel-manage-btn:hover {
  color: @color-OrangePeel;
}

.save-manage-btn,
.save-manage-btn:focus,
.save-manage-btn:active,
.save-manage-btn:hover {
  background-color: @color-FrostedMint;
  color: @color-Java;
}

.select {
  display: block;
  .border();
  width: 350px;
  margin: 10px 0px;
}

.declaration-page-main-tabs-container {
  font-weight: 300;

  .ant-tabs-tab .ant-tabs-tab-btn {
    color: #232326;
    text-shadow: 0 0 0.25px currentColor;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #405cd2;
    text-shadow: 0 0 0.25px currentColor;
  }

  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0 0 0px 0;
  }

  .ant-tabs-ink-bar {
    position: absolute;
    background: #405cd2;
    pointer-events: none;
  }
}
