.date-picker {
  margin: 0;
  padding: 0;
  margin-left: 0.5rem;
}

.note {
  background-color: #fff1de;
  border-radius: 4px;
  font-weight: 600;
}

@primary-color: #405CD2;