.single-employee-profile-select {
  margin-top: 0.5rem;
  border-bottom: #9ba3b4 solid 1.5px;
}

.single-employee-profile-datepicker {
  margin-top: 0 !important;
  padding-top: 0;
  border-bottom: #9ba3b4 solid 1.5px;
}
.ant-input[disabled] {
  border: none !important;
  border-bottom: #aeb1ba solid 2px !important;
  padding-bottom: 0.5rem;
}
