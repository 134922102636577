.separator {
  width: 2px;
  background-color: #d5d6da;
}

.payroll-and-deduction-cards {
  .tds-pf-container {
    width: 100%;
  }

  .employee-container {
    width: 50%;
  }

  .border-left-blue {
    border-left: 7px solid #405cd2;
  }
}
.card-org-styling {
  border-radius: 10px;
  padding: 30px;
  background-color: @color-White;
}
.task-heading {
  text-align: left;
  color: #232326;
  font-size: 22px;
  font-weight: 500;
}

.tasks-container {
  .card-org-styling();
  margin-bottom: 5px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .task-heading();
  .tasks-content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0px;
    .tasks-content-left {
      display: flex;
      justify-content: flex-start;
      .task-text-container {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        .task-type-heading {
          text-align: left;
          color: #151b30;
          font-size: 15px;
          font-weight: 500;
        }
        .task-status {
          text-align: left;
          color: #90919b;
          font-size: 15px;
          font-weight: 400;
        }
      }
    }
    .task-button-container {
      text-align: left;
      color: #405cd2;
      font-size: 15px;
      font-weight: 300;
    }
  }
}

.ctc-container {
  .card-org-styling();
  margin: 5px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .heading-container {
    display: flex;
    justify-content: space-between;
    .task-heading();
  }
  .broadcast-message-container {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
    .broadcast-container-left {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 80%;
      .broadcast-heading {
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        color: #151b30;
      }
      .broadcast-label {
        margin-top: 5px;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #90919b;
      }
    }
    .broadcast-date {
      font-size: 12px;
      font-weight: 400;
      text-align: left;
      color: #90919b;
      margin-right: 15px;
    }
  }
}

.ctc-container {
  .card-org-styling();
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.ads-container {
  height: 8rem;
  width: 100%;
  margin: 20px 0px;
  border: 1px solid transparent;
  padding: 5px;
  .photo-holder {
    img {
      height: 8rem;
      width: 100%;
      overflow: hidden;
      object-fit: fill;
      border-radius: 10px;
    }
  }
}

div#declaration-notification {
  width: 100%;
  position: relative;
  background: #eeeeec;
  height: 100px;
  margin-bottom: 3rem;
  padding: 0 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left-declaration-side {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 4rem;

    .announcement-img {
      width: 58px;
      object-fit: contain;
      margin-bottom: -1rem;
    }
    .declaration-messages h1 {
      font-size: 1.2rem;
      color: #a4a446;
      font-weight: 700;
    }
    .declaration-messages p {
      font-size: 1rem;
      color: #667085;
      font-weight: 500;
    }
  }
  .absolute-close-notification {
    position: absolute;
    right: 1rem;
    top: 1.2rem;
    transform: translate(-50%, -50%);
    font-size: 1rem;
  }
}
