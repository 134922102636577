// 
.content {
    background-color: white;
}

.Calculator-heading {
    font-weight: 700;
    font-size: 24px;
    margin-left: 5em;
    padding-top: 3em;
}

.calculator-forms {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 800px;
    margin-left: 5%;
}

.calculator-forms2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // align-items: center;
    // align-content: center;
    width: 770px;
    margin-left: 5.6%;
}

.calculator-form {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-left: 5%;

}

.calculator-checkbox {
    margin-top: 2em;
    display: flex;
    justify-content: space-between;
    width: 835px;
    margin-left: 5.7%;
}

.calculator-button {
    margin-left: 5.5%;
}

.checkBoxLabel {
    font-size: 18px;
    font-weight: 700;
}

.select {
    width: 100%;
    padding: 0.5rem 0rem !important;
    width: 250px;
    margin-left: -15px;
    border-radius: 4px;
    height: 3rem;
    border: 2px solid #d9d9d9;
    cursor: pointer;

    @media (max-width: 426px) {
        width: 100%;
    }
}

.select-input-field {
    width: 60%;
    margin-left: 3rem;
    float: right;

    @media (max-width: 426px) {
        width: 94%;
        margin-right: 1rem;
        margin-left: 1rem;
    }
}

.calculator-container {
  .calculator {
    padding-top: 10rem;
    margin: 0 auto;
    width: 80%;
    .header {
      display: flex;
      flex-direction: column;
      margin-bottom: 3.5rem;

      @media (max-width: 720px) {
        margin-bottom: 1rem;
      }
      h1 {
        width: 60%;
        font-size: 2.5rem;
        font-weight: 800;
        line-height: 4rem;

        @media (max-width: 720px) {
          width: 100%;
          font-size: 2rem;
          line-height: 3rem;
        }
      }
      .top {
        display: flex;
        align-items: center;
        .line {
          width: 56px;
          height: 4px;
          background-color: #40d2ac;
          margin-right: 12px;
        }
        span {
          font-size: 20px;
          color: #40d2ac;
          font-weight: 500;
        }
      }
    }
    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      z-index: -1;
      background-image: linear-gradient(
        to bottom,
        #f2f3fc 20vh,
        #f4f4fc 5vh,
        #fff 50vh
      );
      height: 50rem;
      width: 98.9vw;
    }

    .content {
      margin-bottom: 5rem;
      padding: 4rem !important;
      background-color: #fff;
      border: 1px solid #eaeaea;
      border-radius: 30px;

      @media (max-width: 720px) {
        padding: 1.5rem !important;
      }
      .heading {
        font-weight: 600;
        font-size: 28px;
        margin-bottom: 2rem;
      }
      .switch-column {
        align-self: center;
      }
      .switch-container {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        font-weight: 600;
        h2 {
          font-size: 1.5rem;
          width: 100%;
        }
        @media (max-width: 425px) {
          align-items: flex-start;
          margin-bottom: 1.5rem;
          h2 {
            font-size: 1.2rem;
          }
        }
        .flex {
          display: flex;
          width: 60%;
          margin: 0 auto;
          align-items: center;
          justify-content: flex-end;
          @media (max-width: 720px) {
            justify-content: center;
            flex-direction: column;
          }
          span {
            margin-right: 1rem;
            font-size: 1rem;
            font-weight: 600;
            @media (max-width: 720px) {
              margin-right: 0;
            }
          }
        }
      }
      .switch-col {
        span.note {
          color: #742ef8;
          font-weight: 600;
          font-size: 0.9rem;
        }
        span.qtn {
          font-size: 1.1rem;
          @media (max-width: 480px) {
            font-size: 1rem;
          }
        }
      }
      .input-field {
        width: 80%;
        padding: 0.4rem 1rem;
        margin: 0.5rem 0;
        border-radius: 4px;
        height: 3rem;
        @media (max-width: 426px) {
          width: 100%;
        }
      }
      .input__field {
        width: 100%;
        padding: 0.4rem 1rem;
        border-radius: 4px;
        height: 3rem;
      }
      h3 {
        margin-top: 1.5rem;
        margin-bottom: -1rem;
        margin-left: 3rem;
        font-weight: 600;
        width: 100%;
      }
      p {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 1.5rem;
        }
        span {
          margin-left: 0.3rem;
          margin-top: 0.1rem;
          font-weight: 600;
          font-size: 1rem;
          color: #fea101;
        }
      }
      .checkbox {
        width: 100%;
        margin-left: 3.2rem;
        margin-top: 1rem;
      }
      .btn {
        background-color: #101111;
        outline: none;
        border: none;
        color: white;
        margin-left: 3rem;
        font-size: 0.9rem;
        padding: 0.8rem 1rem;
        border-radius: 8px;
        margin-top: 1.5rem;
        cursor: pointer;
        a {
          color: white;
        }
        a:visited,
        a:active {
          color: white;
        }
      }
      .result-section {
        h2 {
          font-weight: 700;
          // width: 48%;
          font-size: 1.2rem;
        }
        .header-tag {
          margin-bottom: 4rem;
        }
        .sub-header-1 {
          display: flex;
          align-items: center;
          color: #767b82;
          font-weight: 600;
          img {
            margin-left: 0.5rem;
            width: 64px;
          }
        }
        .sub-header-2 {
          width: 50%;
          font-weight: 500;

          @media (max-width: 500px) {
            width: 100%;
            text-align: justify;
          }
        }
        .tax-savings {
          color: #2ca787;
          font-size: 1.8rem;
          font-weight: 700;
        }
        .takehome {
          color: #3a7ff5;
          font-size: 1.8rem;
          font-weight: 700;
        }
        h2 + span {
          font-size: 1rem;
          font-weight: 600;
        }
        .tag {
          margin: 0;
          font-size: 0.9rem;
          color: #767b82;
          font-weight: 600;
          cursor: auto;
          span {
            margin-left: 0;
            color: #405cd2;
            margin-right: 0.2rem;
          }
        }
        .chart-container {
          display: flex;
          align-items: flex-start;
          @media (max-width: 720px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .chart {
            flex: 1;
          }
          .legend-container {
            flex: 5;
            .flex-calculator {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-top: 1rem;
              margin-bottom: 0rem;
              .line {
                width: 5px;
                height: 30px;
                margin-right: 0.6rem;
                &.takehome {
                  background-color: #ef6922;
                }
                &.tax {
                  background-color: #ffb900;
                }
              }
              .info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-star;
              }
            }
          }
        }
      }
      .result-page-cta {
        button {
          margin-left: 0;
          margin-top: 1rem;
          font-weight: 600;
        }
        .dlod-btn {
          margin-left: 2rem;
          border: none;
          outline: none;
          background-color: #fff;
          font-weight: 600;
          cursor: pointer;
          img {
            margin-right: 0.5rem;
          }
        }
      }
      .select {
        width: 72%;
        padding: 0.5rem 0rem !important;
        margin: 0.5rem 0;
        border-radius: 4px;
        height: 3rem;
        border: 1px solid #eaeaee;
        cursor: pointer;
        @media (max-width: 426px) {
          width: 100%;
        }
      }
      .select-input-field {
        width: 60%;
        margin-left: 3rem;
        float: right;
        @media (max-width: 426px) {
          width: 94%;
          margin-right: 1rem;
          margin-left: 1rem;
        }
      }
      .add-button {
        padding: 0.7rem 2rem;
        cursor: pointer;
        background-color: #405cd2;
        color: white;
        border: none;
        border-radius: 4px;
        @media (max-width: 426px) {
          margin-left: 50px;;
        }
      }
      .allowances-amount-field {
        width: 60%;
        margin-left: 3rem;
        float: right;
      }
      .label-1 {
        margin-left: 0rem;
        font-weight: 600;
        white-space: nowrap;
        margin-bottom: 0;
        cursor: auto;
        @media (max-width: 425px) {
          font-size: 0.8rem;
        }
        .info-btn {
          width: 1.1rem;
          margin-bottom: -2px;
          margin-left: 4px;
          cursor: pointer;
          position: relative;
        }
        .info-content {
          display: none;
          background-color: #000;
          padding: 0.8rem 2rem 0.5rem 2rem;
          border-radius: 8px;
          z-index: 4;
          img {
            min-width: 22rem;
            min-height: 10.5rem;
            max-width: 22rem;
            max-height: 10.5rem;
          }
          p {
            color: white;
            margin-left: 0;
            margin-top: 0.5rem;
          }
          @media (max-width: 425px) {
            img {
              min-width: 19rem;
              min-height: 8.5rem;
            }
          }
        }
        .info-btn:hover + .info-content,
        .info-content:hover {
          display: block;
          position: absolute;
          top: 1.2rem;
          left: 6.5rem;
          @media (max-width: 425px) {
            left: 0;
          }
        }
      }
      .label-2 {
        font-weight: 600;
        color: #858790;
      }
      .label-3 {
        font-weight: 700;
        font-size: 1.3rem;
      }
      .button {
        width: 100%;
        display: flex;
        align-items: center;
        background-color: #fff;
        border: none;
        outline: none;
        margin: 0.5rem 0;
        margin-left: 2.5rem;
        cursor: pointer;
        img {
          height: 1.5rem;
          width: 1.5rem;
          margin-right: 0.2rem;
        }
        span {
          color: #fea101;
          font-weight: 600;
          font-size: 1rem;
          margin-bottom: 0.1rem;
        }
      }
    }
  }
}

// .ant-select.select.ant-select-single.ant-select-show-arrow.ant-select-show-search {
//   border: none;
//   outline: none;
// }

.display-visible {
  display: block !important;
  @media (max-width: 720px) {
    display: none !important;
  }
}

.display-invisible {
  display: none !important;

  @media (max-width: 720px) {
    display: flex !important;
  }
}

.label-1.switch-container {
  .info-btn {
    min-width: 1.5rem;
    margin-bottom: 0rem !important;
  }
  .info-content {
    padding: 1rem 2rem 0.5rem 2rem !important;
    img {
      min-height: 12rem !important;
    }
    p {
      font-size: 0.9rem;
    }
  }
  .info-btn:hover + .info-content,
  .info-content:hover {
    display: block;
    position: absolute;
    top: 4.2rem !important;
    left: 25rem !important;
  }
}

.slider-note {
  color: white;
  margin-left: 0.4rem;
  margin-right: 5rem;
  margin-top: 2rem;
  @media (max-width: 426px) {
    margin-right: 0;
  }
}

.note {
  margin: 0;
  margin-right: 3rem;
  width: 60%;
  color: #405cd2;
  display: block;
  @media (max-width: 426px) {
    display: block;
    width: 100%;
  }
}


.slider-value-container {
  background-color: #0C1D37;
  position: relative;
  flex: 1;
  border-radius: 12px;
  padding: 2rem;

  @media (max-width: 426px) {
    padding: 1rem;
    
  }

}
@primary-color: #405CD2;