.datePickerDiv {
    position: relative;

    .ant-picker {
        &.hasValue:not(.ant-picker-focused){
            border: 2px #5E5F62 solid !important;
        }
        z-index: 1 !important;
        background-color: transparent !important;
        padding: 0.5rem 13px !important;
        border: 1px #dbdbe5 solid !important;

        &:hover:not(.ant-picker-focused){
            border-color: #9c9ca1 !important;
        }
        &.hasValue:not(.ant-picker-focused) ~ .labelup {
            color: #5E5F62 !important;
        }
    }

    .ant-picker-focused {
        border: 2px solid #405cd2 !important;
        box-shadow: 0 0 0 0 !important;
        ~.labelup{
            color: #405cd2 !important;
        }
        ~.labelnormal {
            color: #405cd2;
            top: -0.6rem;
            font-size: 14px;
            left: 0.5rem;
            padding: 0 6px;
            z-index: 2;
        }
    }
}
@primary-color: #405CD2;