.password-reset-container {
  background-color: #f4f4ff;
  min-height: 100vh;
  width: 100vw;
  //   background-image: url("../assets/landingPage/password-reset-background.png");

  .password-reset-main-container-container {
    width: 50%;

    .password-reset-main-container {
      margin: 0px auto;
      width: 480px;
      border-radius: 20px;
      background-color: @color-White;
      box-shadow: 0px 0px 70px 4px #c4badb;

      .blue-header-box {
        border-radius: 20px 20px 0px 0px;
        background-color: @color-Mariner;
        font-size: 16px;
        padding: 16px 30px;

        .blue-box-info-text {
          color: @color-White;

          .blue-box-span {
            font-weight: 600;
          }
        }
      }

      .password-reset-main-content-conatiner {
        padding: 45px;
        .message-box-image-container {
          margin-bottom: 16px;
          .message-box-image {
            height: 50px;
          }
        }
        .password-reset-main-content-header {
          color: @color-Shark;
          font-weight: 800;
          font-size: 34px;
          margin: 16px 0px;
        }
        .mail-id-container {
          font-size: 16px;
          font-weight: 400;
          margin: 36px 0px;
          .mail-id-general-text {
            font-weight: 300;
            color: @color-Manatee;
          }
          .mail-id-of-user {
            color: @color-Shark;
          }
        }
        .code-container {
          .code-input-container {
            margin: 16px 0px;
          }
          .resend-mail-text {
            color: @color-Manatee;
            font-weight: 300;
            font-size: 15px;
          }
        }
        .password-fields-container {
          .password-field-container {
            margin-bottom: 40px;
            .password-field-label {
              color: @color-Manatee;
              font-weight: 300;
              font-size: 15px;
            }
          }
        }
        .password-reset-actions-button-container {
          margin-top: 84px;
          margin-left: -15px;
        }
        .password-reset-actions-button-container-1 {
          margin-top: 60px;
          margin-bottom: 36px;
        }
        .user-info-holder {
          display: flex;
          margin-bottom: 60px;
          margin-top: 36px;

          .user-logo-container {
            display: flex;
            align-items: center;
            justify-content: center;
            .employee-avatar,
            .organisation-avatar {
              height: 50px;
              padding: 8px;
              background-color: @color-Shamrock;
              border-radius: 50px;
            }
            .organisation-avatar {
              background-color: @color-PeachCream;
            }
          }
          .user-info {
            .userType {
              color: @color-ManateeLite;
              font-size: 15px;
              font-weight: 300;
            }
            .userName {
              color: @color-Shark;
              font-size: 16px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .hero-text-container {
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .hero-text {
      width: 80%;
      font-size: 60px;
      font-weight: 800;
      color: #6640d2;
      .hugging-face-image {
        height: 60px;
        margin-left: 16px;
      }
    }
  }
}

.password-reset-successfull-container {
  background-color: #f4f4ff;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  .password-reset-successfull-text-box {
    width: 430px;
    height: 300px;
    background-color: @color-White;
    border-radius: 10px;
    box-shadow: 0px 0px 70px 4px #c4badb;
    padding: 40px;

    .password-reset-successfull-heading {
      font-size: 24px;
      color: @color-Shark;
      font-weight: 600;
      margin-bottom: 24px;
    }

    .password-reset-successfull-text {
      color: @color-ManateeLite;
      font-size: 15px;
    }
    .password-reset-successfull-button-container {
      margin-top: 70px;
      text-align: right;
    }
  }
}
