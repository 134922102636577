#refresh-btn p.active {
  animation: rotate 2s linear infinite;
}
#refresh-btn p.in-active {
  animation: rotate 0s linear infinite;
}

@keyframes rotate {
  10% {
    transform: rotate(30deg);
  }
  30% {
    transform: rotate(90deg);
  }
  40% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(240deg);
  }
  60% {
    transform: rotate(360deg);
  }
  70% {
    transform: rotate(-240deg);
  }
  80% {
    transform: rotate(-180deg);
  }
  90% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(-30deg);
  }
}

@primary-color: #405CD2;