@media screen and (max-width: 535px) {

  .price-model-largeScreen {
    background-color: red;
    display: none !important
  }

}

@media screen and (min-width: 535px) {
  .price-model-PhoneScreen {
    display: none;
  }
}

@media screen and (max-width: 535px) {
  .price-model-PhoneScreen {

    padding: 0em 2em 2em;
  }
}

.collapse-p-l-margin {
  margin-left: 1em;
  font-size: 15px;
  color: #5E5F62;
  font-weight: 500;
  margin-top: 1em;
}

.starter-div-mobile {
  border: solid grey 1px;
  border-radius: 7px;
  margin-top: 10px;
  box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.25);
}

.standard-div-mobile {
  border: solid grey 1px;
  border-radius: 7px;
  margin-top: 50px;
  box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.25);

}

.starter-heading {
  margin-left: 1em;
  margin-top: 30px;
}

.popular-span {
  background-color: #FFB900;
  color: #55400A;
  padding: 5px 15px;
  font-size: 16px;
  font-weight: 500;
  float: right;
}

.INR-span {
  font-weight: 600;
  margin-left: 1.75em;
  display: inline-block;

}

.price-span {
  font-size: 50px;
  font-weight: 700;
  margin-left: 10px;
  margin-top: 20px;
  display: inline-block;
}

.month-employee-span {
  display: inline-block;
}

.ant-collapse-item.test-panel-header>.ant-collapse-header {}

.attachDiv {
  width: 100%;
  height: 100%;
}

.navbar {
  border-bottom: 1px solid #80808040;
}

.main {
  display: flex;
  width: 100%;
}

.contentDiv {
  padding: 3rem 3rem 0rem 3rem;
}

.textDiv {
  padding: 2% 20% 0 0;
}

.draggerDiv {
  padding: 2% 60% 0 0;
}

.btnDiv {
  padding-top: 2%;
  margin-left: -5px;
}

.textP {
  padding-top: 2%;
}

.formDiv {
  padding: 3rem 0 0rem 0;
}

.bold {
  font-weight: bold;
}

.endCollapse>.bold {
  font-weight: bold;
  width: 24vw;
}

.feature-collapsable-rows {
  color: red;
}

.grey {
  color: #5e5f62;
}

.collapsible {
  padding: 0 3rem 3rem 0;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  color: #0c1d37;
  width: 30rem;
  font-weight: bold;
  font-size: 16px;
}

.ant-collapse-item.mainPanel>.ant-collapse-header {
  color: #405cd2;
  font-size: 18px;
  width: 33vw;
}

.ant-collapse {
  background: transparent;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-image: url("../../assets/icons/bluetick.svg");
  font-size: 1rem;
  font-weight: bolder;
  margin-right: 1rem;
  z-index: 2;
}

.orange-tick {
  background-repeat: no-repeat;
  background-image: url("../../assets/icons/bluetick-single.svg");
}

.blue-tick {
  background-repeat: no-repeat;
  background-image: url("../../assets/icons/bluetick-single.svg");
}

.red-cross {
  background-repeat: no-repeat;
  background-image: url("../../assets/icons/redcross.svg");
}

.m-r-6 {
  margin-right: 6vw;
}

.mb-1 {
  margin-bottom: 1rem;
}

.no-border {
  border: 0;
  width: 26vw;
}

.lighter {
  font-weight: lighter;
}

.endCollapse {
  width: 68vw;
  padding-left: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.12rem;
  margin-bottom: 1.12rem;
}

.noPanelDiv {
  width: 69vw;
  color: #5e5f62;
  font-weight: 600;
}

.top-view {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 3rem 0 0;
  // height: 46vh;
  margin-top: 3rem;
}

.font-size-64 {
  font-size: 3rem;
}

sup {
  top: -2.5em;
  margin-right: 0.5rem;
}

mark {
  background-color: red;
  color: white;
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 6px;
  display: inline-block;
}

.vl-1 {
  border-left: 1px solid rgb(210, 216, 230);
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
  left: 35vw;
  top: 0;
}

.hover {
  cursor: pointer;
}

.vl-2 {
  border-left: 1px solid rgb(210, 216, 230);
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
  left: 53vw;
  top: 0;
  width: 18vw;
  background-color: rgba(64, 92, 210, 0.05);
}

.top-col1 {
  width: 35vw;
}

.top-col2,
.top-col3 {
  width: 18vw;
}

.fw-600 {
  font-weight: 600;
}

.checkCircle {
  margin: 2px 5px;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  font-size: 1rem;
}

.moolLogoDiv {
  width: 26vw;
  padding: 2rem 3rem 3rem 3rem;
  background-image: linear-gradient(155deg, #e7ebfd -6%, #a3b5ff 114%);
}

.collapse-col {
  width: 71vw;
}

.most-popular {
  position: relative;
  top: -1.3em;
  width: 18vw;
  height: 5rem;
  margin: -2.8vw 0vw 0vw -1.9vw;
  text-align: center;
  padding-top: 0.5vw;
  background-color: #feba01;
  z-index: -1;
  border-radius: 4rem;
  font-size: 1.125rem;
  font-weight: bold;
}

.top-col3>.ant-col {
  margin-top: -2rem;
}

// .noPanelDiv > .font-size-14:first-child {
//   width: 24rem;
// }
@media screen and (max-width: 1024px) {
  .endCollapse {
    width: 64vw;
  }

  .noPanelDiv {
    width: 66vw;
  }

  .top-col3>.ant-col {
    margin-top: -3rem;
  }
}


@media screen and (max-width: 1280px) {
  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    width: 50vw;
  }

  .sidebar {
    width: 10vw;
  }

  .top-view {
    padding-right: 0;
    justify-content: flex-start;
  }

  .contentDiv {
    padding-right: 0;
  }

  .collapsible {
    padding: 0;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px) {
  .endCollapse {
    width: 67.5vw;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .endCollapse {
    width: 66.5vw;
  }

  .noPanelDiv {
    width: 68vw;
  }
}

@media screen and (max-width: 768px) {
  .top-col3>.ant-col {
    margin-top: -4rem;
  }

  .moolLogoDiv {
    display: none;
  }

  .contentDiv {
    padding: 0.5rem;
  }

  .collapse-col {
    width: 100vw;
  }

  .top-col1 {
    width: 40vw;
  }

  .top-col2,
  .top-col3,
  .most-popular {
    width: 30vw;
  }

  .vl-1 {
    left: 39vw;
  }

  .vl-2 {
    left: 68.25vw;
    width: 30vw;
  }

  .endCollapse {
    width: 90vw;
  }

  .noPanelDiv {
    width: 92vw;
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    font-size: 0.8rem;
  }

  .ant-collapse-item.mainPanel>.ant-collapse-header {
    font-size: 1rem;
  }

  .most-popular {
    width: 29.5vw;
    font-size: 1rem;
  }

  .font-size-64 {
    font-size: 2.2rem;
  }

  // .noPanelDiv:first-child {
  //   width: auto;
  // }
}

@media screen and (max-width: 475px) {
  .vl-1 {
    left: 38vw;
  }

  .vl-2 {
    left: 66.6vw;
  }
}

@media screen and (max-width: 400px) {
  .most-popular {
    font-size: 0.5rem;
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    font-size: 0.7rem;
  }

  .ant-collapse-item.mainPanel>.ant-collapse-header {
    font-size: 0.8rem;
  }

  .vl-1 {
    left: 36vw;
  }

  .vl-2 {
    left: 65.6vw;
  }

  .endCollapse>.font-size-14 {
    font-size: 0.5rem;
  }
}

.price-model-largeScreen {}
@primary-color: #405CD2;