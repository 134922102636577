@import "./typography.less";
@border-radius-month: 10px;
.button-month {
  color: @color-OrangePeel;
  background-color: @color-Serenade;
  .border();
  font-size: 16px;
  border-radius: @border-radius-month;
}
.container-month {
  background-color: @color-White;
  border-radius: @border-radius-month;
}
.month-close-cards-data-heading {
  .h5-bold-20();
}
.amount-debit {
  color: @color-Red;
}
.amount-credit {
  color: @color-Shamrock;
}
.month-close-container {
  width: 95%;
  border-radius: @border-radius-month;
  margin: 0px auto;
  margin-top: 25px;

  .month-closing-fields-container {
    .container-month();
    margin-bottom: 10px;
    .org-anb-data-container {
      .display-flex-column();
      padding: 0px;

      .headings-fields-heading {
        color: @color-ManateeLite;
        margin-top: 10px;
      }
      .ant-radio-group {
        margin-top: 10px !important;
      }
      .ant-input-number-lg input {
        height: 13px !important;
      }
    }

    .field-label {
      font-size: 16px;
      font-weight: 400;
      display: block;
    }

    .fields-containers {
      margin: 20px 0px;

      .input-field {
        margin-top: 10px;
        border-radius: 0.2rem;
        outline: none;
        height: 50px;
      }
    }

    .select-button-container {
      .display-justify-content-space-between();
      align-items: flex-end;
      margin: 25px 0;

      .field-type-option {
        width: 100px;
        margin-top: 10px;
      }

      .add-field-button {
        .button-month();
      }
    }
  }

  .month-closing-files {
    // margin-top: 2rem;
    .container-month();

    .dragger {
      margin: 0;
      padding: 0 10px;
      .dragger-info-container {
        display: flex;
        align-items: center;
        margin-left: 3rem;
        .uploadLogo {
          color: @color-Shamrock;
          font-size: 40px;
        }
        .ant-upload-text {
          margin-top: 10px;
          margin-left: 16px;
          color: #90919b;
          text-align: left;
        }
      }
    }

    .month-closing-files-button {
      .button-month();
      margin-right: 20px;
      margin: 40px 20px 0px 0px;
    }
  }

  .current-month-closing-fields-container {
    .container-month();
  }
}

.header-monthclose-bulk {
  .display-justify-content-space-between();
}

.paper-wrapper-anb {
  background-color: @color-White;
  padding: 30px 30px 0 30px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.paper-wrapper-month-closing {
  background-color: @color-White;
  padding: 10px 30px;
  border-radius: 10px;
  margin-bottom: 20px;
}
