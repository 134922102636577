.h1-bold-24 {
  font-weight: 700;
  font-size: 24px;
}

.h1-bold-32 {
  font-weight: 700;
  font-size: 32px;
}

.h5-bold-20 {
  font-weight: 700;
  font-size: 20px;
}

.h5-bold-18 {
  font-weight: 700;
  font-size: 18px;
}
.h1-bold-72 {
  font-weight: bold;
  font-size: 4.5rem;
}

.h2-bold-60 {
  font-weight: bold;
  font-size: 3.75rem;
}

.h3-bold-48 {
  font-weight: bold;
  font-size: 3rem;
}

.h4-bold-36 {
  font-weight: bold;
  font-size: 2.25rem;
}

.h4-bold-30 {
  font-weight: bold;
  font-size: 30px;
}

.h5-bold-20 {
  font-weight: bold !important;
  font-size: 20px !important;
}

.h6-bold-18 {
  font-weight: bold;
  font-size: 1.125rem !important;
}
.h7-bold-19 {
  font-weight: bold;
  font-size: 1rem !important;
}
.h8-regular-12 {
  font-size: 10px !important;
}
.h8-bold-12 {
  font-weight: bold;
  font-size: 13px !important;
}
.h7-bold-20 {
  font-weight: bolder !important;
  font-size: 1.1rem !important;
}
.subtitle3-med-16 {
  font-weight: 600;
  font-size: 1rem;
}

.subtitle1-med-16 {
  font-weight: 500;
  font-size: 1rem;
}

.subtitle2-med-14 {
  font-weight: 500;
  font-size: 0.875rem;
}

.subtitle3-med-16 {
  font-weight: 700;
  font-size: 1rem;
}

.subtitle4 {
  font-weight: 500;
  font-size: 0.8rem;
  color: #5e5f62;
}

.body1.med-16 {
  font-weight: 500;
  font-size: 1rem;
}

.body2-med-14 {
  font-weight: 500;
  font-size: 0.875rem;
}

.button-text {
  font-weight: 600;
  font-size: 1.125rem;
}

.separating-text-semi-16 {
  font-weight: 600;
  font-size: 1rem;
}

.notifcation-text-med-14px {
  font-weight: 500;
  font-size: 0.875rem;
}
