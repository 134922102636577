html,
body {
  margin: 0px;
  height: 100%;
}

.register-container {
  height: 100%;
}

.registerImage {
  height: 100vh;
}

.registerFormDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.registerForm {
  margin: 1% 1% 0 1%;
}

.mool-Logo {
  display: none;
}
.registerLabel {
  padding: 5% 0;
}

.register-btn-container {
  margin-top: 3%;
}

.goto-login-container {
  text-align: center;
  margin-top: 5%;
}
.registerForm button {
  width: 500px;
  height: 50px;
}
@media screen and(max-width:1024px) {
  .imageCol {
    display: none;
  }

  .ant-spin-container > .ant-row {
    justify-content: center;
  }
}

@media screen and(max-width:660px) {
  .registerForm {
    width: 100vw;
    margin: 1rem;
  }

  .registerForm button {
    width: 100vw;
    height: 50px;
    margin: 0 1vw;
  }
}

@media screen and (max-width: 980px) {
  .mool-Logo {
    display: block;
    margin: 2vw 0;
  }
}
