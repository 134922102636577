.requestLeave {
    .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content{
    margin-left: 0;
    }
    .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom:1rem;
    }
}
@primary-color: #405CD2;