@margin-process:20px;
.month-close-settlement {
    margin-top: @margin-process;
    min-height: 450px;
    
    .step-container {
        height: 100%;
        padding: 20px 0;
        // box-shadow: 0px -1px 0 0 #e8e8e8 inset;
    }
}

.settlement-body {
    margin-top: @margin-process;
}