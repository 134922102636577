.input-field-container {
  padding-top: 8px;
  border: none;
  background-color: #fff;
  border-bottom: #cdcdd4 2px solid;
  width: 100%;
  cursor: text;
  outline: none;
  box-shadow: none;
  font-size: 16px;
  color: #232326;
  font-weight: 400;
  border-radius: 2px;
  transition: all 0.3s;
}

.input-field-3 {
  border-bottom: #f26b6b 2px solid;
}

.input-field-container:hover:enabled,
.input-field-container:active:enabled,
.input-field-container:focus:enabled {
  border-bottom: #405cd2 2px solid;
}

// .input-field-container:active:enabled,
// .input-field-container:focus:enabled {
//   box-shadow: 0px 2.5px 1px #c8cee7;
// }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.input-field-4 {
  border: #dfdfdf 1px solid;
  padding: 5px 10px;
  border-radius: 5px;
}

.input-field-4:hover:enabled,
.input-field-4:active:enabled,
.input-field-4:focus:enabled {
  border-bottom: #405cd2 0px solid;
  border: #405cd2 1px solid;
  box-shadow: none;
}
.ant-input-affix-wrapper {
  border: none;
  border-bottom: #ced7e5 solid 2px;
  padding-bottom: 0.5rem;
  padding-left: 0.1rem;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  outline: none;
  box-shadow: none;
}

.ant-input-affix-wrapper-disabled {
  background-color: #fff;
}

@primary-color: #405CD2;