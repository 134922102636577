.reimbursement-container {
  background-color: @color-White;
  border-radius: 10px;

  .claim-reimbursement-container {
    background-color: @color-AthensGrey;
    border-radius: 10px;
    padding: 32px;
    height: 100%;

    .claim-reimbursement-heading {
      font-size: 24px;
      font-weight: 800;
      color: @color-Tundora;
      @media (max-width: 600px) {
        font-size: 17px;
      }
    }

    .ant-upload-text {
      @media (max-width: 600px) {
        font-size: 14px !important;
      }
    }

    .ant-upload-hint {
      @media (max-width: 600px) {
        font-size: 14px !important;
      }
    }
  }
}

h2.claim-reimbursement-heading {
  font-size: 24px;
  font-weight: 800;
}

.page-heading {
  font-size: 36px;
  font-weight: 600;
}

.upload-doc-card {
  margin: 0px 5px;
  .upload-doc-div {
    .upload-doc-name {
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.claim-reimbursement-data-container {
  padding: 12px 0px;
  .claim-reimbursement-labels {
    font-weight: 400;
    font-size: 16px;
    color: @color-ManateeLite;
    display: block;
    margin-bottom: 12px;
    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  

  .expense-date-date-picker {
    background-color: @color-AthensGrey;
    width: 100%;
    border-bottom: @color-AthensGray 2px solid;
  }

  .expense-date-date-picker:hover,
  .expense-date-date-picker:active,
  .expense-date-date-picker:focus,
  .claim-reimbursement-inputs:hover,
  .claim-reimbursement-inputs:active,
  .claim-reimbursement-inputs:focus {
    border-bottom: @color-Mariner 2px solid;
  }

  .claim-reimbursement-inputs {
    display: block;
    background-color: @color-AthensGrey;
    border-bottom: @color-AthensGray 2px solid;
  }
}

.update-reimbursement-modal {
  .update-reimbursement-modal-body {
    padding: 16px;

    .update-reimbursement-header {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 24px;
    }

    .update-reimbursement-modal-button {
      text-align: right;
      margin-top: 24px;
    }
  }
}
