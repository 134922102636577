.org-data-container {
  .ant-slider-rail {
    height: 18px;
    border-radius: 18px;
  }
}

.allowance-panel {
  .ant-collapse-header {
    font-size: 24px;
    font-weight: 700;
  }
}

.allowance-row {
  .ant-divider-horizontal {
    margin: 8px 0;
  }

  .ant-divider-vertical {
    height: 6em;
    margin: 0 33px;
  }
  .org-data-container {
    display: flex;
    height: 5em;
  }
  .ant-input-num{
    width: 120px;
  }
}
.declaration-class{
  .ant-collapse{
    background-color: #f6f6f6;
    margin:1rem 0;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header{
    width: auto;
  }
  .ant-input-num{
    width: 120px;
  }
}

//   .ant-input-number-affix-wrapper-focused{
//     border:none;
//   }

@primary-color: #405CD2;