.modal-class {
    .ant-table-tbody>tr.ant-table-row-selected>td {
        background-color: #f8fbff;
        padding: 10;
    }
}

.modal-class {
    td.ant-table-cell {
        height: 10px;
    }
}
.modal-class {
    th.ant-table-cell {
        height: 10px;
        padding: 10;
        background-color: white;
        font-size: 12px;
    }
}

.modal-class {
    .ant-modal-close-x {
        margin: 15px;
        margin-right: 20px;
    }
}
.modal-class {
    .ant-modal-body {
        padding-top: 0px;
    }
}
.modal-class {
    .ant-modal-header {
        padding: 30px;
        border-bottom: 0;
        padding-bottom: 20px;
    }
    .ant-modal-title{
        font-weight: 700;
        font-size: 22px;
    }
}
.modal-class {
    .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        height: 0;
    }
}
.state-one {
    .ant-modal-body {
            padding: 0px;
        }
    }


@primary-color: #405CD2;