.currency-format {
  font-size: 13px;
  color: @color-Manatee;
  font-weight: 400;
  margin-right: 5px;
}

.amount {
  font-size: 13px;
  font-weight: 700;
  @media (max-width: 600px) {
    font-size: 15px;
  }
}

.card-data-heading {
  font-size: 25px;
  color: @color-Manatee;
  font-weight: 400;
}

.header-component {
  .display-justify-content-space-between();
  align-items: center;
  margin-bottom: 10px;

  .header-link {
    color: #405cd2;
    display: flex;
    align-items: center;

    img {
      height: 15px;
      width: 15px;
      margin-right: 2px;
    }
  }
}

.border-left-blue {
  border-left: 7px solid #405cd2;
}
.ctc-card-container {
  background-color: @color-White;
  padding: 10px 30px;
  border-radius: 5px;
  height: 100%;

  .info-container {
    padding: 24px 0px;
    .display-justify-content-space-between();

    @media (max-width: 600px) {
      .display-flex-column-justify-content-center();
    }

    .separator {
      width: 2px;
      background-color: #d5d6da;
    }
    .ctc-data-container {
      margin-left: 10px;
      .card-data-heading {
        margin-bottom: 5px;
        @media (max-width: 600px) {
          font-size: 16px;
        }
      }
      .currency-format {
        @media (max-width: 600px) {
          font-size: 15px;
        }
      }
      .amount {
        font-size: 15px;
        font-weight: 700;
      }
    }
  }
}

.tax-sheet-annual-salary-container {
  background-color: @color-White;
  padding: 36px;
  border-radius: 10px;
  height: 100%;
  .display-flex-column-justify-content-space-around();
  align-items: baseline;

  .download-cards-container {
    .display-center-content-space-between();
    margin-top: 10px;
    width: 100%;

    .download-card-logo-name-container {
      .display-align-center();

      .download-card-logo-container {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 24px;

        .download-card-logo {
          display: block;
          margin: auto;
          margin-top: 25%;
          width: 50%;
        }
      }
    }

    .view-button-container {
      .view-button {
        .border();
        color: @color-OrangePeel;
      }
    }
  }
}

.company-info-card-container {
  background-color: @color-White;
  padding: 36px;
  border-radius: 10px;
  .display-flex-direction-column-align-items-flex-start();
  justify-content: center;

  .company-info {
    .display-align-center();
    margin: 10px 0px;

    .company-logo {
      margin-right: 20px;
      height: 36px;
    }

    .company-name {
      font-size: 25px;
      font-weight: 600;
    }
  }
}

.bottom-row {
  .chart-cards-container {
    height: 100%;
    .charts-card {
      background-color: @color-White;
      border-radius: 10px;
      padding: 24px;
      min-height: 400px;
      height: 100%;

      .card-data-heading {
        margin-bottom: 16px;
      }

      .amount-and-option-button-container {
        .display-center-content-space-between();
        .currency-format {
          font-size: 16px;
          color: @color-Manatee;
          font-weight: 300;
          margin-right: 16px;
        }

        .chart-amount {
          font-size: 48px;
          font-weight: 600;
        }
      }
    }
  }

  .pay-slip-card-conatiner {
    background-color: @color-White;
    border-radius: 10px;
    height: 100%;

    .pay-slip-card-info-container {
      background-color: @color-Mariner;
      color: @color-White;
      border-radius: 10px 10px 0px 0px;
      text-align: center;
      padding: 10px;

      .pay-slip-card-info-heading {
        font-size: 14px;
        color: @color-White;
      }
    }

    .pay-slip-card-body {
      padding: 15px 20px;

      .pay-slip-heading-container {
        .display-justify-content-space-between();

        .pay-slip-heading {
          font-size: 16px;
          font-weight: 600;
        }

        .see-all-button {
          color: @color-OrangePeel;
          background-color: @color-White;
          .border();
          font-size: 16px;
        }
      }

      .monthly-payslip-container {
        overflow: auto;
        width: 100%;
        max-height: 340px;
        margin-top: 10px;

        .monthly-payslips {
          .display-center-content-space-between();
          padding: 5px 5px;
          color: @color-Jumbo;

          .pay-silp-view-button {
            color: @color-Mariner;
            background-color: @color-White;
            .border();
            font-weight: 400;
          }
        }

        hr {
          border: @color-WildSand solid 1px;
        }
      }
    }
  }
}

.update-scroll {
  max-height: 300px;
  overflow-y: scroll;
  scrollbar-width: none;
}


.timer {
  .flex();
  .flex-direction-column();
  .align-center();
  .justify-center();
  margin-bottom: 10px;

  .countdown {
    color: @color-moolGreen;
    font-size: 40px;
    text-align: center;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .countdown-gray {
    color: @color-Shark;
  }
  .subtitle {
    margin-bottom: 3rem;
  }
}

.footer-separator {
  border-top: 0.5px solid #eaeaee;
  padding: 5px;
  margin-top: 15px;

  .left-container {
    padding: 5px;
    border-right: 0.5px solid @color-AthensGray;
    color: @color-Manatee;
    width: 70%;
    font-size: 12px;
  }

  .right-container {
    padding: 5px;
    color: @color-Manatee;
    width: 30%;
    font-size: 12px;
    text-align: right;
  }
}

.info-header {
  color: @color-Manatee;
  font-size: 14px;
  margin: 10px 0 5px;
}

.information {
  color: @color-Shark;
  font-size: 20px;
  font-weight: 700;
  margin: 5px 0 10px;
}

.weekly-attendance {
  background-color: @color-White;
  padding: 30px;
  border-right: 0.5px solid @color-Alto;
  height: 100%;
}

.weekly-report {
  background-color: @color-White;
  height: 100%;
  padding: 20px;
}

.attendance-header {
  display: flex;
  .dashboard-month-selected {
    width: 200px;
    font-weight: 700;
    font-size: 25px;
    color: @color-Shark;
  }
  .week-date {
    font-size: 17px;
    font-weight: 700;
    color: @color-TextBlack;
    margin: 0 10px;
  }
}

.weekly-info-header {
    color: @color-Manatee;
    text-align: left;
    font-size: 13px;
}

.weekly-info-body {
    color: @color-moolGreen;
    font-size: 23px;
    font-weight: 700;
    text-align: left;
}

.extra-inforamtion-1 {
    background-color: #fff1f1;
    color: #f26b6b;
}

.extra-inforamtion-2 {
    background-color: #ededf2;
    color: #151b30;

    .info {
        color: #585a64
    }
}

.extra-information {
    padding: 18px;
    border-radius: 5px;
    margin-bottom: 10px;
    .header {
        font-weight: 500;
        font-size: 18px;
    }

    .info {
        font-size: 12px;
    }
}

.attendance-container {
  margin-top: 10px;
}

.attendace-0 {
  background-color: #fff;
  // border-bottom: 0.2px solid #7e808a;
  .date-time-container {
    .day {
      color: #151b30;
    }
    .month {
      color: #7e808a;
    }
  }
  .misc-attendance {
    .message-container {
      color: #7e808a;
    }
  } 
}

.attendace-1 {
  background-color: #fff1f1;
  // border-bottom: 0.2px solid #f26b6b;
  .date-time-container {
    .day {
      color: #f26b6b;
    }
    .month {
      color: #f26b6b;
    }
  }
  .misc-attendance {
    .message-container {
      color: #f26b6b;
    }
  } 
}

.attendace-2 {
  background-color: #f7f7fa;
  // border-bottom: 0.2px solid #f7f7fa;
  .date-time-container {
    .day {
      color: #151b30;
    }
    .month {
      color: #7e808a;
    }
  }
  .misc-attendance {
    .message-container {
      color: #f7f7fa;
    }
  } 
}

.attendace-3 {
  background-color: #f7f7fa;
  // border-bottom: 0.2px solid #7e808a;
  .date-time-container {
    .day {
      color: #151b30;
    }
    .month {
      color: #7e808a;
    }
  }
  .misc-attendance {
    .message-container {
      color: #7e808a;
    }
  } 
}

.attendace-4 {
  background-color: #fff6e6;
  // border-bottom: 0.2px solid #fea101;
  .date-time-container {
    .day {
      color: #fea101;
    }
    .month {
      color: #fea101;
    }
  }
  .misc-attendance {
    .message-container {
      color: #fea101;
    }
  } 
}


.attendace {
  display: flex;
  align-items: center;
  padding: 15px 15px;
  margin-top: 1px;
  .date-time-container {
    margin-right: 10px;
    width: 18%;
    .day {
      font-size: 15px;
      font-weight: 700;
    }
    .month {
      font-size: 13px;
      font-weight: 500;
    }
  }
  .misc-attendance {
    width: calc(79%);
    display: flex;
    align-items: center;

    .message-container {
      margin-right: 10px;
      font-size: 12px;
      font-weight: 500;
    }
  } 
}



.timer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #adafb7;

  .timer {
    font-size: 35px;
    font-weight: 600;
    text-align: left;
    color: #22232a;
  }
  .login-data {
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #adafb7;
    margin: 10px 0px;
  }
  .login-button {
    width: 100%;
    margin: 20px 10px;
    background: @color-Shamrock 0% 0% no-repeat padding-box;
    border-radius: 3px;
    border: none;
    height: 40px;
    color: @color-White;
  }
}

.details-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #585a64;
  .image-container {
    border-left: 1px solid #adafb7;
    padding-left: 30px;
  }
}

.leave-container {
  .leave-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .leave-heading {
      font-size: 16px;
      font-weight: 600;
      text-align: left;
      color: #151b30;
    }
  }
  .leave-types{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .leave-types-heading{
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      color: #90919b;
    }
    .leave-types-details{
      font-size: 15px;
      font-weight: 500;
      text-align: left;
      color: #151b30;
    }
  }
}

.apply-leave-header{
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #151b30;
}
.border-line{
  padding: 0px;
  margin-top: 10px;
  border-bottom: 1px solid #d5d6df;
}
.leave-main-container{
 
  .leave-header{
    font-size: 1.5rem;
    font-weight: 700;
    text-align: left;
    color: #444444;
  }
  .employee-leave-select{
    width: 100%;
  }
}
.dragger {
  margin-top: 15px;
  padding: 0 10px;
  .dragger-info-container {
    height: 5.3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .uploadLogo {
      color: #9898a5;
      font-size: 40px;
    }
    .ant-upload-text {
      margin-top: 10px;
      margin-left: 16px;
      color: #90919b;
      text-align: left;
    }
  }
}

.request-container {
  padding: 20px;
  .request-heading {
    font-size: 20px;
    color: #444444;
    font-weight: 500;
    text-align: left;
  }
}

.name-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .employee-name {
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    color: #2a2b2b;
  }
  .employee-id {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: #93979b;
  }
}

.type-label {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  color: #2a2b2b;
  display: inline-flex;
}
.type-data {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  color: #93979b;
}

.report-container {
  padding: 20px;
  .report-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .report-heading {
      font-size: 20px;
      color: #444444;
      font-weight: 500;
      text-align: left;
    }
    .button-container {
      color: #fea101;
    }
  }
}

.leave-modal {
  width: 850px;
  .leave-modal-container {
    padding: 20px;
    .modal-heading {
      font-size: 18px;
      font-weight: 600;
      text-align: left;
      color: #151b30;
    }
    .modal-data-container {
      .leave-select {
        width: 100%;
      }
      .leave-input {
        width: 100%;
      }
    }
    .employees-holder {
      width: 100%;
      height: 20rem;
      border: solid 0.5px #dbdbdb;
      padding: 10px;
      overflow-y: scroll;
    }
    .checkbox {
      margin: 10px 0;
    }
  }
}

.columnContainer {
  border-left: solid 0.5px #dbdbdb;
}

.approved {
  font-size: 12px;
  font-weight: 700;
}

.approved-0 {
  .approved();
  color: orange;
}

.approved-1 {
  .approved();
  color: green;
}

.approved-2 {
  .approved();
  color: red;
}


.add-new-leave-modal {
  .add-new-leave-modal-heading {
    padding: 0px 20px;
  }
  .add-new-leave-modal-body {
    padding: 20px;
  }
}

@border-radius-support: 10px;
.phone-holder-style {
  color: @color-Shark;
  font-weight: 700;
  font-size: 17px;
  padding: 15px 2px;
}

.support-container {
  width: 95%;
  border-radius: @border-radius-support;
  margin: 0px auto;
  margin-top: 25px;

  .table-container {
    background-color: @color-White;
    padding: 20px;
    border-radius: @border-radius-support;

    .table-container-heading {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 15px;
    }
  }
}
.form-container {
  background-color: @color-White;
  .display-flex-column-justify-content-center();
  padding: 20px;
  height: auto;
  border-radius: @border-radius-support;

  .form-heading {
    margin-bottom: 15px;
  }
  .form-data-container {
    margin-bottom: 15px;

    .phone-number-holder {
      .phone-country-code {
        .phone-holder-style();
      }
      .phone-number {
        margin-left: 10px;
        .phone-holder-style();
      }
    }
  }

  .form-type {
    display: block;
  }

  .form-labels {
    font-weight: 500;
    font-size: 16px;
    color: @color-ManateeLite;
    display: block;
    margin-bottom: 8px;
  }
}

.floating-div {
  position: fixed;
  width: 18rem;
  bottom: 2rem;
  right: 3.6rem;
  overflow: hidden;
  background-color: #fafbff;
  padding-left: -5rem;
  background-color: #f2f4ff;
  z-index: 20;
  border-radius: 12px;
  box-shadow: 0 1.5px 7px 0 rgba(0, 0, 0, 0.16);
  .ant-collapse-content-box {
      padding: 0px;
    }
}



.panel-num {
  border-radius: 100%;
  margin-left: '-15rem';
  padding:0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  background-color: #fff;
  width: 2rem;
  border: solid #9ea0a8 .1rem;
}

.panel-div {
  display: flex;
  align-items: center;
  margin-left: 4rem;
}

.below-inner-collapse {
  margin: 0 1.9rem;
} 

.justify-number-heading {
  display: flex;
  align-items: center;
  padding: 0 .5rem;
}

.tick {
  height: 1.1rem;
  width: 1.1rem;
}

.non-collapse {
  transform: rotate(90deg);
  margin-left: .6rem;
  height: .6rem;
  width: .6rem;
}

.non-collapse-up{
  transform: rotate(270deg);
    margin-left: .6rem;
    height: .6rem;
    width: .6rem;
}

.collapsed {
  margin-left: 1rem;
  height: .6rem;
  width: .6rem;
}

.justify-div {
  display: flex;
  justify-content: space-between;
  width: 13rem;
}

.panel-container {
  margin-bottom: '5rem';
  margin-left: '-4rem';
  border: 'none !important';
}

.font-heading {
  font-weight: 400;
}

.ant-progress-inner{
  background-color: #d8dfff;
}