.border-block {
  .border();
  width: 350px;
  display: block;
}
.files-header {
  margin-bottom: 24px;
  margin-top: 8px;
}
.files-heading {
  font-size: 24px;
  font-weight: 400;
}
.input-field {
  .border();
  border-bottom: 1px @color-ManateeLite solid;
  font-size: 16px;
  font-weight: 400;
}

.allowance-info {
  .allowance-info-heading1 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .allowance-info-heading2 {
    font-size: 24px;
    font-weight: 300;
    color: @color-Manatee;
    margin-bottom: 30px;
  }

  .para {
    color: @color-Manatee;
    font-size: 17px;
    margin: 24px 0px;
    line-height: 28px;
  }
}

.hra-files-upload-container {
  margin-top: 8px;
  max-height: 100%;
  .ant-upload {
    height: 150px;
  }

  .hra-files-header {
    .display-center-content-space-between();
    margin-bottom: 15px;

    .hra-files-heading {
      .files-heading();
    }
  }

  .hra-files-monthly-rent-container {
    margin-bottom: 15px;

    label {
      margin-bottom: 8px;
      color: @color-ManateeLite;
      font-size: 16px;
    }

    .monthly-rent-input-field {
      margin-bottom: 16px;
      .input-field();
      width: 125px;
    }
  }

  .hra-files-home-agrement-container {
    margin-bottom: 15px;
    .hra-files-home-agrement-heading {
      margin-bottom: 16px;
      color: @color-ManateeLite;
      font-size: 16px;
    }
  }

  .hra-files-house-owner-pan-conatiner {
    display: flex;
    flex-direction: column;
  }
}

.child-info-container {
  label {
    margin: 12px 0px;
    color: @color-ManateeLite;
    font-size: 16px;
  }

  input,
  input:focus {
    .border-block();
    border-bottom: 2px solid @color-AthensGray;
    margin: 10px 0px;
  }
  .child-info-header-container {
    .display-justify-content-space-between();
    .files-header();

    .child-info-heading {
      .files-heading();
    }
  }

  .ant-radio-group {
    display: block;
  }

  .ant-picker {
    .border-block();
  }
}

.child-files-container {
  .child-files-header {
    .files-header();
    .child-files-heading {
      .files-heading();
    }
  }
}

.hostel-files-container {
  .hostel-files-header {
    .files-header();
    .hostel-files-heading {
      .files-heading();
    }
  }
}

.otherAllowance-files-container {
  .otherAllowance-files-header {
    .files-header();
    .otherAllowance-files-heading {
      .files-heading();
    }
  }
}

.hostel-info-container {
  .note {
    margin-bottom: 32px;
    color: @color-ManateeLite;
  }

  label {
    margin: 12px 0px;
    color: @color-ManateeLite;
    font-size: 16px;
  }

  input,
  input:focus {
    .border-block();
    margin: 10px 0px;
  }
  .hostel-info-header-container {
    .display-justify-content-space-between();
    .files-header();

    .hostel-info-heading {
      .files-heading();
    }
  }

  .hostel-info-class-and-academic-year-conatiner {
    .display-justify-content-space-between();

    .class-container {
      min-width: 45%;

      input {
        width: 100%;
      }
    }
  }

  .ant-radio-group {
    display: block;
  }

  .ant-picker {
    .border-block();
  }
}

.ant-upload {
  margin: 10px 0px;
  height: 200px;
}
