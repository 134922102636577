.main .attachDiv {
  width: 100%;
  height: 100%;
}
.main .navbar {
  border-bottom: 1px solid #80808040;
  position: sticky;
  top: 0;
  z-index: 99;
}

.main .main {
  display: flex;
  width: 100%;
}

.sign-outDiv {
  text-align: right;
  height: 70px;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 30px;
  position: sticky;
  background-color: white;
}

.main .contentDiv {
  padding: 3rem;
}

.main .textDiv {
  padding: 2% 20% 0 0;
}

.draggerDiv {
  padding: 2% 60% 0 0;
}

.main .btnDiv {
  padding-top: 2%;
  margin-left: -5px;
}

.main .textP {
  padding-top: 2%;
}
.main .formDiv {
  padding: 3rem 0 2rem 0;
}

.main .bold {
  font-weight: bold;
}
.main .grey {
  color: #5e5f62;
}
.main .footer {
  bottom: 0;
  width: 78vw;
  background-color: #f2f2ff;
  display: flex;
  padding: 10px;
}

.main .inputDiv {
  margin-top: 1.125rem;
}
.main .b-500 {
  font-weight: 500;
}

.main .moolLogoDiv {
  width: 26vw;
  padding: 2rem 3rem 3rem 3rem;
  background-image: linear-gradient(155deg, #e7ebfd -6%, #a3b5ff 114%);
}
.main .divider {
  height: 41rem;
  margin-left: 9rem;
  margin-right: 3rem;
  font-size: 2rem;
}

@media screen and (max-width: 1440px) and (min-width: 768px) {
  .main .divider {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .main input {
    width: 24rem;
  }
  .main .col1 {
    width: 21rem;
  }
  .main .col2 {
    width: 21rem;
  }
  .coupon .coupon-input {
    width: 14.7rem;
  }
}
@media screen and (max-width:900px) {
  .main .divider{
    display: none;
  }
  
}
@media screen and (max-width: 768px) {
  .main .sidebar {
    width: 10vw;
  }

  .main .moolLogoDiv {
    padding: 1rem;
  }
  .main .footer {
    width: 100vw;
  }
  .main input {
    width: 24rem;
  }
  .main .col1 {
    width: 21rem;
  }
  .main .col2 {
    width: 21rem;
  }
  .col2 .coupon {
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .col2 .coupon .coupon-input {
    height: 6vh;
  }
  .coupon .coupon-apply {
    margin-top: 1.2rem !important;
    margin-bottom: 1rem;
  }
}

@primary-color: #405CD2;