.emoloyee-list-container {
  padding: 27px 33.3px 32.5px 31.8px;
  border-radius: 7.5px;
  background-color: @color-White;

  .header-container {
    .display-center-content-space-between();
    width: 100%;
    height: 70px;
    @media (max-width: 600px) {
      .display-flex-direction-column-align-items-flex-start();
      width: auto;
      height: auto;
    }

    h3 {
      .font-style-left();
      font-size: 24px;
      line-height: 1.23;
      color: @color-Tundora;
      @media (max-width: 600px) {
        font-size: 20px;
      }
    }
    .employee-icons {
      @media (max-width: 600px) {
        display: inline;
      }
    }

    .action-container {
      .display-flex-align-items-center-justify-content-flex-end();
      width: 70%;
      @media (max-width: 600px) {
        .display-flex-direction-column-align-items-flex-start;
        width: auto;
        height: auto;
      }

      .employee-icons {
        font-size: 20px;
        margin-right: 20px;
      }

      .employee-list-search-field {
        max-width: 150px;
        padding: 4.5px 7px 4.5px 10px;
        margin-right: 20px;
        border-radius: 3px;
        background-color: @color-Gallery;
        @media (max-width: 600px) {
          margin: 4px;
        }
      }
      .button-container {
        .display-flex-align-items-center-justify-content-flex-end();
        margin: 0;
        @media (max-width: 600px) {
          margin: 4px;
        }
      }
    }
  }
}

.modal-container {
  border-radius: 5px;

  .ant-modal-close{
    top: 2rem;
    right:3rem;
  }
}

.modal-body {
  .header-display {
    .display-flex-align-items-center-justify-content-flex-end();
    margin: 5px 0;
  }

  .modal-header {
    width: 100%;

    img {
      height: 20px;
      width: 20px;
    }

    p {
      margin-left: 10px;
      font-size: 15px;
      text-align: left;
      color: @color-Tundora;
    }
  }

  .allowances-container {
    .display-justify-content-space-between();
    flex-direction: column;
    max-height: 340px;
    overflow-y: scroll;
    width: 100%;

    .Employee-Upload-Status {
      width: 100%;
      p {
        font-size: 24px;
        font-weight: 400;
        line-height: 1.83;
        text-align: left;
        color: @color-Shark;
        margin: 0;
      }

      .response-data {
        width: 100%;
        .display-center-content-space-between();
        padding: 0 10px;

        p {
          font-size: 15px;
          font-weight: 300;
        }
      }
      .success {
        p {
          color: @color-JapaneseLaurel;
          font-weight: 300;
        }
      }
      .fail {
        p {
          color: @color-Red;
          font-weight: 300;
        }
      }
    }
  }

  .submit-container-modal {
    display: flex;
    justify-content: flex-start;
    margin: 0px 8px;
    margin-top: 1.5rem;
  }
}

.employee-list-section-header {
  .display-align-center();
  margin-bottom: 30px;
  .employee-list-section-heading {
    margin-left: 16px;
    font-size: 26px;
    font-weight: 700;
  }
}

.payslip-upload-header {
  .display-center-content-space-between();
  margin-bottom: 30px;

  .employee-list-section-heading {
    font-size: 22px;
    font-weight: 700;
  }
}

.ant-modal-content {
  border-radius: 10px;
}

.payslip-data-upload {
  margin: 20px 0;
  .display-justify-content-space-between();

  p {
    span {
      color: @color-JapaneseLaurel;
    }
  }
}

.actionIcon{ 
  border: 'solid'!important;
  border-radius:50% !important;
  padding:'2px' !important;
  color: '#9ea0a8' !important;
}

.activeActionIcon{ 
  border: 'solid';
  border-radius:50%;
  padding:'2px';
  color: '#405cd2' 
}
