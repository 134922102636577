.org-report-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 16px 30px;
  margin: 22px 0px;
  border: #d5d6df 1px solid;
  &:hover {
      background-color: #405cd2;
      border-radius: 7px;
      cursor: pointer;
      p {
          color: white;
        }
  }

  .org-report-type {
    color: @color-Tundora;
    font-size: 18px;
    font-weight: 700;
  }
  .org-report-subtitle {
    color: #505057;
    font-size: 14px;
    font-weight: 500;
    @media (max-width: 600px) {
      font-size: 12px;
    }
  }
}

.org-custom-report-card {
  background-color: #fff;
  border-radius: 10px;
  margin: 22px 0px;
  border: #d5d6df 1px solid;

  .org-report-type {
    color: @color-Tundora;
    font-size: 18px;
    font-weight: 700;
  }
  .org-report-subtitle {
    color: #505057;
    font-size: 14px;
    font-weight: 500;
    @media (max-width: 600px) {
      font-size: 12px;
    }
  }
    &:hover {
      background-color: #405cd2;
      border-radius: 7px;
      cursor: pointer;
      .org-custom-report-cart-left-side{
        p {
            font-size: 1.2rem;
            color: white;
            font-weight: 600;
          }
      }
    }

  .org-custom-report-cart-left-side {
    width: 90%;
    padding: 16px 0px 16px 30px;
  }
  .org-custom-report-cart-right-side {
    width: 10%;
    padding: 16px 30px 16px 0px;
    text-align: right;
  }
}
