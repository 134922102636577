.platform {
    display: flex;
    align-items: center;
    padding: 1.5rem;
    border: 0.5px solid @color-ManateeDark;
    cursor: pointer;
    margin: 1rem;
    border-radius: 10px;

    .platform-img {
        width: auto;
        height: 50px;
        margin-right: 1em;
    }

    p {
        font-size: 1rem;
    }
}

.platforms-list {
    display: flex;
    height: calc(100vh - 300px);
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}