.submit-login-flow {
  background: @color-Shamrock 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  height: 40px;
  letter-spacing: 0px;
  color: @color-White;
  margin: 0px;
}
.skip-login-flow {
  background: @color-White 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  height: 40px;
  letter-spacing: 0px;
  color: #bcbdc8;
}
.first-login-flow-container {
  min-height: 100vh;
  width: 100%;
  // .Logo-container-login-flow {
  //   position: absolute;
  //   top: 1.5rem;
  //   left: 2rem;
  // }
  .display-align-center-justify-content-center();
  //background: url("../../assets/LoginFlowBackground.svg");
  .img-background();
  align-items: flex-start !important;
  @media (max-width: 450px) {
    width: 100%;
    height: auto;
    padding: 10px;
  }
}
.stage-login-flow-container {
  // max-width: 780px;
  width: 60%;
  background: @color-White 0% 0% no-repeat padding-box;
  border-radius: 15px;
  padding: 25px 25px 10px;

  @media (max-width: 1200px) {
    width: 50%;
    padding: 35px;
  }

  @media (max-width: 1100px) {
    width: 60%;
    padding: 35px;
  }

  @media (max-width: 650px) {
    width: 80%;
    padding: 30px;
  }

  @media (max-width: 450px) {
    width: 100%;
    padding: 20px;
  }
}
.stage-login-flow-heading {
  font-size: 2.25rem;
  .font-style-left();
  font-weight: 700;
  line-height: 1.32;
  color: @color-Shark;
}
.stages-login-flow {
  width: 100%;
  padding: 8px 2px;
  .display-justify-content-space-between();
  align-self: flex-start;
  flex-direction: column;
}
.ant-upload-dicon-login-flow {
  .ant-upload-drag-icon {
    color: @color-Mariner;
  }

  .ant-upload-hint {
    color: @color-BlackDark;
    font-size: 0.8rem;
    text-align: center;
    line-height: 15px;
    font-weight: 200;
  }
}

.input-field-styles {
  max-width: 50%;
  padding-top: 0 !important;
}
