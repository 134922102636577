.reimbursement-header {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 24px;
}

.attachment-buttons {
  min-width: 40px;
  min-height: 40px;
  padding: 0px;
  text-align: center;
  color: @color-FrenchGray;
  margin-right: 16px;
  border: @color-FrenchGray 1px soild;
  border-radius: 5px;
}

.reimbursement-container {
  background-color: @color-White;
  border-radius: 10px;
  padding: 40px;

  .reimbursement-table {
    margin: 20px 0px;
    color: @color-FrenchGray;

    .actions-buttons {
      .attachment-buttons();
    }

    .select-status {
      width: 100%;
      .status-logo {
        margin-right: 8px;
      }
    }
  }

  .status-conatiner {
    min-width: 20px;
    .display-align-center();
    .status-logo {
      margin-right: 8px;
      display: block;
    }
  }

  .status-pending {
    background-color: @color-PeachCream;
    padding: 8px;
    margin: 0px auto;
    border-radius: 10px;
    color: @color-SelectiveYellow;
    font-weight: 400;
  }
}

.page-heading {
  font-size: 24px;
  font-weight: 600;
  @media (max-width: 600px) {
    font-size: 20px;
  }
}

.status-select {
  display: block;
  padding: 0px;
  .border();

  .status-option {
    padding: 20px 0px;
    display: block;
  }
}

.rejection-modal {
  min-width: 20vw;

  .rejection-modal-header {
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 24px;
  }

  .rejection-text-box {
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 16px;
  }

  .rejection-button {
    text-align: right;
  }
}

.accept-modal {
  min-width: 20vw;
  .accept-modal-header {
    .reimbursement-header();
  }

  .accept-modal-text-box {
    .accept-modal-text {
      font-size: 16px;
      font-weight: 500;
      margin: 24px 0px;
    }

    .accept-modal-doucments-container {
      .display-align-center();
      margin: 16px 0px;

      .accept-modal-buttons {
        .attachment-buttons();
      }

      .accept-modal-status-conatiner {
        font-size: 16px;
        font-weight: 400;
        margin-left: 8px;

        .accept-modal-status-logo {
          height: 16px;
          width: 16px;
          margin-right: 8px;
        }
      }
    }
  }

  .accept-modal-button {
    text-align: right;
  }
}
