.right {
  z-index: 100;
  flex: 1.2;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  padding:25px;
  .top {
    padding: 1rem 1rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px 8px 0 0;
    .title {
      h3 {
        color: #232326;
        font-size: 21px;
        font-weight: 700;
      }
      p {
        font-size: 0.8rem;
      }
    }
    p {
      font-size: 0.7rem;
    }
  }
  .bottom {
    margin: 0rem -1.75rem;
    display: flex;
    flex-wrap: wrap;
    .entry {
      // width: 45%;
      padding: 1rem;
      display: flex;
      align-items: center;
      margin: 0 0rem;
      .bigger-container {
        width: 3rem;
        margin-left: 1.8rem;
        .img-container {
          width: 100%;
          height: 2.70rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          img {
            width: 1.2rem;
          }
        }
      }
      h3 {
        flex: 12;
      }
      .btn {
        margin-right: 1rem;
        padding: 0.5rem;
        border-radius: 5px;
        margin-left: 1rem;
      }
    }
    entry:hover {
      background-color: yellow;
    }
  }
}

.main-oboard-div {
  width: 100%;
  background-color: #fff;
  overflow: hidden;
}
@primary-color: #405CD2;