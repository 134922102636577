.settings-container {
  background-color: @color-White;
  padding: 3rem;
  border-radius: 10px;
}
.settings-logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 24px;
}
.organisation-setting-container {
  .settings-container();
  height: 100%;

  .pane-heading {
    .display-center-content-space-between();
    padding-bottom: 16px;
    font-size: 1.5rem;
    font-weight: 500;
  }

  .submit-button-container {
    justify-self: end;
  }
  .main-body-container-2 {
    width: 30%;
  }
}

.employee-profile-container {
  .settings-container();

  .pane-heading {
    padding: 0px 0px 1rem 0px;
    font-size: 1.3em;
    font-weight: 500;
    .display-center-content-space-between();
  }

  .employee-basic-info {
    .display-align-center();
    margin: 10px 0;

    .employee-logo-container {
      margin-right: 2rem;

      .employee-logo {
        background-color: @color-OrangePeel;
        border-radius: 50%;
      }
    }

    .employee-name-container {
      margin-right: 2rem;
    }

    .active-button,
    .active-button:hover {
      border: none;
      background-color: @color-FrostedMint;
      color: @color-Java;
      border-radius: 0.5rem;
    }
  }
}

.avatar-update.ant-upload.ant-upload-select-picture-card {
  width: 500px;
}

.company-data {
  .company-data-cards {
    height: 100%;
    .company-data-card-company-about {
      margin: 32px 0px;
    }

    .company-data-company-code {
      font-size: 48px;
      font-weight: 800;
      margin: 32px 0px;
    }
    .company-data-cards-info {
      color: @color-Shamrock;
    }
  }
}

.company-address-container {
  padding: 42px 48px;
  margin-bottom: 20px;
  height: 100%;
  .media-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .media-heading {
      font-size: 20px;
      font-weight: 500;
    }
  }
  .company-address-individual-data-container {
    margin: 32px 0px;
  }
}

.photo-update-container {
  .display-align-center();

  .current-logo-holder {
    margin-top: 16px;
    margin-right: 2rem;
    width: 60px;
    height: 60px;

    .ant-modal-body {
      .display-content-center();

      .avatar-update {
        display: block;
        width: 500px;
      }
    }

    .upload-container {
      .display-align-center-flex-column();
      justify-self: center;
      height: 100%;
      width: 100%;
      padding: 0;
    }
    .current-logo {
      height: 100%;
    }
  }

  .change-button {
    background-color: @color-Serenade;
    border-radius: 4px;
    border: none;
    color: @color-OrangePeel;
  }
}

.organisation-data-container {
  margin-bottom: 20px;
  // height: 100%;

  .organisation-data-heading-container {
    margin-bottom: 32px;
    .organisation-name-logo-container {
      .display-align-center();

      .current-logo-holder-has-logo {
        .display-center-content-space-between();

        .default-logo {
          background-color: @color-PeachCream;
          .settings-logo();
        }

        .current-logoo {
          .settings-logo();
          object-fit: contain;
        }
      }
    }
  }
}

.ca-default-background {
  background-color: @color-caGreen;
  border-radius: 50%;
  margin-right: 16px;
  width: 70px;
  height: 70px;
}

.sticky {
  position: fixed;
  top: 10%;
  right: 4%;
  z-index: 1111;
  background: rgba(255, 255, 255, 0.534);
  backdrop-filter: blur(8px);
  border-radius: 10px;
}


.structure-container{
  padding:20px;
  background: rgba(97, 111, 235, 0.26);
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  border-radius: 5px;
}