.ant-input-number{
  padding: 0 !important;
  font-weight: 200 !important;
  z-index: 1 !important;
  background-color: transparent !important;
  &:not(:focus-within).ant-input-number-input:placeholder-shown{
    border: 1px #d9d9d9 solid !important;
  }
  &:not(:focus-within)~.labelnormal{
    color: #9898a5 !important;
  }
  &:not(:focus-within).ant-input-number:hover{
    border-color: #9c9ca1 !important;
  }
  &:not(:focus-within) ~ .labelup:not(.error){
    color: #5E5F62 !important;
  }
  
}
.ant-input-number-input{
  font-weight: 500 !important;
  padding: 0.5rem 13px !important;
  height: 100% !important;
}
.ant-input-number[disabled] {
  border: 1px solid #9898a5 !important;
  padding-bottom: 0 6px !important;
}
.inputnumberdiv .labelnormal,.labelup{
  z-index:0;
  position: absolute;
  left: 0.4rem;
  top: calc(50% - 0.75rem);
  padding: 0 6px;
  color: #9898a5;
  font-size: 1rem;
  font-weight: 500;
  cursor: text;
  transition: top 0.2s ease-in,
  left 0.2s ease-in,
  font-size 0.2s ease-in;
  background-color: white;
  &.error {
    color: #F05050 !important;
    top: -0.6rem;
    font-size: 14px;
    left: 0.5rem;
    padding: 0 6px;
    z-index: 2 !important;
  }
}
.ant-input-number-focused{
    border: 2px #405cd2 solid !important;
    box-shadow: 0 0 0 0 !important;
  ~ .labelnormal{
  color: #405cd2 !important;
  top: -0.6rem;
  font-size: 14px;
  left: 0.5rem;
  padding: 0 6px;
  z-index: 2;
  }
  ~.labelup{
    color: #405cd2 !important;
  }
}
.labelup{
  font-family: 500 !important;
  top: -0.6rem;
  font-size: 14px;
  left: 0.5rem;
  padding: 0 6px;
  z-index: 2;
  color: #9898a5;
  &.error {
    color: #F05050 !important;
    animation: shake 0.2s ease-in-out 0s
  }
}
.ant-input-number-input-wrap{
  height: 100% !important;
}
@keyframes shake {
  0% {
    margin-left: 0rem;
  }

  25% {
    margin-left: 0.5rem;
  }

  75% {
    margin-left: -0.5rem;
  }

  100% {
    margin-left: 0rem;
  }
}
.errormessage{
  visibility: hidden;
  margin-top:2px;
  display:block;
}
.activeerrormessage{
  visibility:visible; 
  color:#F05050;
  margin-top:2px;
  display:block;
}

@primary-color: #405CD2;