.status {
  padding: 10px;
  display: inline-block;
  text-align: center;
  font-size: 0.6rem;
  border-radius: 7.5px;
  font-weight: 400;
  .status-img {
    margin-right: 3px;
    height: 0.6rem;
    width: 0.6rem;
  }
}

.status-1 {
  background-color: #fff1de;
  color: #feba01;
}

.status-2 {
  background-color: #e4fff8;
  color: #40d2ac;
}

.status-3 {
  background-color: #ffeeee;
  color: #f26b6b;
}

@primary-color: #405CD2;