.height-border {
  height: 82vh;
  border-radius: 10px;
  background-color: @color-White;
}

.button {
  .border();
  padding: 0px 20px;
  border-radius: 8px;
  font-size: 17px;
}

.card-headers {
  font-size: 24px;
  margin-bottom: 24px;
  font-weight: 600;
}

.payslip-container {
  .height-border();

  .payslip-header-container {
    padding: 16px 36px;
    text-align: left;
    border-radius: 10px 10px 0px 0px;
    background-color: @color-Mariner;

    .payslip-header {
      color: @color-White;
    }
  }

  .payslip-body-container {
    padding: 36px;

    .monthly-payslip-container {
      overflow: auto;
      height: 62vh;

      .monthly-payslips {
        .display-center-content-space-between();
        padding: 10px 0px;
        color: @color-Jumbo;
        font-size: 15px;

        .pay-silp-view-button {
          .button();
          color: @color-OrangePeel;
          background-color: @color-Serenade;
        }
      }

      hr {
        border-top: @color-WildSand 1px solid;
      }
    }
  }
}

.form-16-container {
  .height-border();
  padding: 36px;

  .financial-year-list-container {
    overflow: auto;
    height: 67vh;

    .financial-year-container {
      .display-justify-content-space-between();
      padding: 16px 0px;
      font-size: 17px;

      .financial-year {
        color: @color-Jumbo;
      }

      .financial-year-view-button {
        color: @color-FrenchGray;
        .button();
      }
    }
  }
}