h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}
.display-align-center {
  display: flex;
  align-items: center;
}
.display-content-center {
  display: flex;
  justify-content: center;
}
.display-flex-column {
  display: flex;
  flex-direction: column;
}
.display-center-content-space-between {
  .display-align-center();
  justify-content: space-between;
}
.display-justify-content-space-between {
  display: flex;
  justify-content: space-between;
}
.display-flex-direction-column-align-items-flex-start {
  .display-flex-column();
  align-items: flex-start;
}
.display-flex-align-items-center-justify-content-flex-end {
  .display-align-center();
  justify-content: flex-end;
}
.display-align-center-justify-content-center {
  .display-align-center();
  justify-content: center;
}
.display-flex-column-justify-content-space-around {
  .display-flex-column();
  justify-content: space-around;
}
.display-flex-column-justify-content-center {
  .display-flex-column();
  justify-content: center;
}
.display-align-center-justify-content-space-around {
  .display-align-center();
  justify-content: space-around;
}
.display-flex-justify-content-flex-end {
  display: flex;
  justify-content: flex-end;
}
.display-align-center-justify-center-flex-column {
  .display-align-center-justify-content-center();
  flex-direction: column;
}
.display-align-center-flex-column {
  .display-align-center();
  flex-direction: column;
}

.font-style-left {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}
.font-style-right {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
}

.border {
  border: none;
  box-shadow: none;
}

.img-background {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.document-container {
  .display-center-content-space-between();
  margin: 3px 0;
  .file-typeHeader {
    width: 50px;
    height: 50px;
    margin: 0 10px 0;
    font-weight: 400;
    border-radius: 2px;
    background-color: @color-FairPink;
    color: @color-MediumCarmine;
    .display-align-center-justify-content-center();
  }
  span {
    text-align: left;
    font-weight: 500;
  }
  .date-contianer {
    line-height: 2.14;
    font-size: 12px;
    letter-spacing: normal;
    text-align: left;
    color: @color-FrenchGray;
  }
  .verifiection-text {
    letter-spacing: normal;
    font-size: 13px;
    text-align: left;
    float: right;
  }
  .verified {
    color: @color-Shamrock;
  }
  .not-verified {
    color: @color-OrangePeel;
  }
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

// .ant-input-number-input:focus,
// .ant-input-number-input:active {
//   border-bottom: @color-Mariner solid 1.5px !important;
// }

.ant-upload.ant-upload-drag {
  .ant-upload-drag-container {
    .display-align-center-justify-center-flex-column();

    .ant-upload-text {
      margin: 0 0 4px;
      color: @color-Dullblack;
      font-size: 0.8rem;
    }

    .ant-upload-dicon-login-flow();
  }
}

.ant-modal-header {
  border-radius: 10px;
}

.m-top-minus-1 {
  margin-top: -1.5rem;
}


.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 50px;
}