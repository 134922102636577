@border-radius: 10px;
@margin-bottom: 10px;

.settlement-container {
  width: 98%;
  border-radius: @border-radius;
  margin: 0px auto;
  margin-top: 15px;
  .settlement-form {
    background-color: @color-White;
    padding: 50px;
    border-radius: @border-radius;
    margin-bottom: @margin-bottom;
  }
  .settlement-list {
    background-color: @color-White;
    padding: 40px;
    border-radius: @border-radius;
    margin-bottom: @margin-bottom;
  }
}


.withdraw-pf {
  margin-top: 16px;
  font-size: 1rem;
  color: @color-ManateeLite
}