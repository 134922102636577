@color-Manatee: #90919b;
@color-White: #fff;
@color-OrangePeel: #fea101;
@color-Mariner: #405cd2;
@color-Jumbo: #73737b;
@color-Shark: #232326;
@color-AthensGray: #eaeaee;
@color-WildSand: #f5f5f5;
@color-Serenade: #fff7ec;
@color-FrenchGray: #bbbbbf;
@color-OysterBay: #e1faff;
@color-Tundora: #444444;
@color-Java: #15cbac;
@color-FrostedMint: #e4fff8;
@color-AthensGrey: #f7f7fa;
@color-SharkLite: #2d2e30;
@color-ManateeLite: #9898a5;
@color-Shamrock: #40d2ac;
@color-Alto: #cfcfcf;
@color-ManateeDark: #99999f;
@color-SeashellSolid: #f1f1f1;
@color-Gray: #888888;
@color-Emperor: #555555;
@color-Black: #000000;
@color-Blue: #0000ff;
@color-MidGray: #5f5f65;
@color-Froly: #f26b6b;
@color-Denim: #1777e5;
@color-Gallery: #eeeeee;
@color-JapaneseLaurel: #008000;
@color-Red: #ff0000;
@color-GreyDark: #9191913f;
@color-BlackLite: #000000d9;
@color-BlackDark: #00000073;
@color-TomGray: #f0f2f5;
@color-Mischka: #d5d6df;
@color-FairPink: #ffeeee;
@color-MediumCarmine: #b73939;
@color-FrenchGray: #bcbdc8;
@color-MacaroniandCheese: #ffbc8b;
@color-PeachCream: #fff1de;
@color-Nevada: #6b7175;
@color-SelectiveYellow: #feba01;
@color-TitanWhite: #f0f2ff;
@color-Dullblack: #000000d9;
@color-FuelYellow: #f3a02e;
@color-FineBlack: #91919140;
@color-AthensGrayLite: #f2f2f3;
@color-FairManatee: #9c9ea2;
@color-caGreen: #43de90;
@color-titanWhite: #e8ecff;
@color-havelockBlue: #637adc;
@color-Manatee:#90919b;
@color-White:#fff;
@color-OrangePeel:#fea101;
@color-Mariner:#405cd2;
@color-Jumbo:#73737b;
@color-Shark:#232326;
@color-AthensGray:#eaeaee;
@color-WildSand:#f5f5f5;
@color-Serenade:#FFF7EC;
@color-FrenchGray:#bbbbbf;
@color-OysterBay:#E1FAFF;
@color-TextBlack: #151b30;
@color-Tundora:#444444;
@color-Java:#15cbac;
@color-FrostedMint:#e4fff8;
@color-AthensGrey:#f7f7fa;
@color-SharkLite:#2d2e30;
@color-ManateeLite:#9898a5;
@color-Shamrock:#40d2ac;
@color-Alto:#cfcfcf;
@color-ManateeDark:#99999f;
@color-SeashellSolid:#f1f1f1;
@color-Gray:#888888;
@color-Emperor:#555555;
@color-Black:#000000;
@color-Blue:#0000FF;
@color-MidGray:#5f5f65;
@color-Froly:#f26b6b;
@color-Denim:#1777e5;
@color-Gallery:#eeeeee;
@color-JapaneseLaurel:#008000;
@color-Red:#ff0000;
@color-GreyDark:#9191913f;
@color-BlackLite:#000000d9;
@color-BlackDark:#00000073;
@color-TomGray:#f0f2f5;
@color-Mischka:#d5d6df;
@color-FairPink:#ffeeee;
@color-MediumCarmine:#b73939;
@color-FrenchGray:#bcbdc8;
@color-MacaroniandCheese:#ffbc8b;
@color-PeachCream:#fff1de;
@color-Nevada:#6b7175;
@color-SelectiveYellow:#feba01;
@color-TitanWhite:#f0f2ff;
@color-Dullblack:#000000d9;
@color-FuelYellow:#F3A02E;
@color-FineBlack:#91919140;
@color-AthensGrayLite:#f2f2f3;
@color-FairManatee:#9c9ea2;
@color-caGreen:#43de90;
@color-titanWhite : #e8ecff;
@color-havelockBlue : #637adc;
@color-moolGreen: #40d2ac;

.grey {
  color: #505057;
}
