.file-viewer-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 51;
  opacity: 1;
  background-color: black;

  .top-bar {
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    color: #fff;
    font-weight: 800;
    letter-spacing: 1px;

    .top-bar-left {
      display: flex;
      align-items: center;
      .back-button-container {
        margin-right: 16px;
      }

      .file-type-container {
        margin-right: 16px;
        .file-type-background {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 5px;
          font-weight: 800;
          font-size: 14px;
          color: #fff;
        }

        .file-type-pdf {
          background-color: #f26b6b;
        }

        .file-type-jpg {
          background-color: #6640d2;
        }
      }
    }

    .top-bar-right {
      .verified-text-container {
        margin-right: 2rem;
        font-size: 18px;
        font-weight: 300;
        .verified-logo-img {
          margin-right: 12px;
          height: 22px;
          width: 22px;
        }
      }
    }
  }

  .document-display-area-zoom-container {
    overflow: auto;
    width: 90vw;
    height: 90vh;
    margin: 0px auto;

    .document-display-spin-container {
      position: relative;
      margin: auto;
      z-index: 101;
    }

    .document-display-area {
      height: 100%;
      object-fit: contain;

      .document-display {
        max-height: 100%;
        display: block;
        z-index: 101;
        object-fit: contain;
      }
    }
  }
}

.bottom-nav-panel-container {
  position: absolute;
  z-index: 51;
  bottom: 0;
  width: 100%;
  left: 0;

  .bottom-nav-panel {
    margin: 0px auto;
    z-index: 102;
    color: #fff;
    font-size: 16px;
    letter-spacing: 0.8px;
    background-color: #000;
    width: 400px;
    height: 65px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.document-display-area-zoom-container::-webkit-scrollbar {
  display: none;
}

.document-display-area-zoom-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@primary-color: #405CD2;