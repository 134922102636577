.SignUpContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 25%;
    width: 100%;
}

.signUpMessage {
    text-align: center;
    padding-top: 14px;
    background-color: white;
    border: #dedce2 2px solid;
    border-radius: 10px;
    width: 400px;
    font-weight: 500;
    font-size: 16px;
    position: relative;
    color: rgba(0, 0, 0, 0.6);
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 20px;
}


.signUpMessageHeader {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 16px;
    color: black;

}

.signUpButton {
    margin-top: 20px;
}

.backGroundImage {
    position: absolute;
    filter: blur(15px);
    -webkit-filter: blur(12px);
}
@primary-color: #405CD2;