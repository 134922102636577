.month-select {
  width: 200px;
  margin-left: 16px;
  border-bottom: @color-AthensGray 2px solid;
  color: @color-ManateeLite;
  font-size: 16px;
  font-weight: 600;
}

.month-picker {
  margin-left: 16px;
  width: 150px;
}

.custom-report-modal-container {
  min-width: 700px;
}

.customer-report-name-container {
  margin-right: 18px;
  min-width: 300px;
}

.customer-report-fields-container {
  margin-left: 18px;
  min-width: 300px;
  .customer-report-checkbox-container {
    margin-top: 14px;
    max-height: 350px;
    overflow-y: auto;
  }
}

.full-separator {
  width: 2px;
  background-color: #e6e6e6;
}
