@import url("https://use.typekit.net/yej7igg.css");
body {
  margin: 0;
  font-family: proxima-nova, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
