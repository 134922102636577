.inputdiv .ant-input {
  z-index: 1 !important;
  background-color: transparent !important;
  padding: 0.5rem 13px !important;
  border: 1px #dbdbe5 solid !important;
  font-weight: 500 !important;

  &.errorState {
    border: 2px #F05050 solid !important;

    &:hover {
      border: 2px #F05050 solid !important;
    }
  }
}

.inputdiv .ant-input {
  &:hover {
    border: 1px #9c9ca1 solid !important;
  }
}

.inputdiv .ant-input:focus {
  border: 2px #405cd2 solid !important;
  box-shadow: 0 0 0 0 !important;
}

.inputdiv .ant-input[disabled] {
  border: 1px solid #9898a5 !important;
  padding-bottom: 0 6px !important;
}

.labelnormal {
  z-index: 0;
  position: absolute;
  left: 0.4rem;
  top: calc(50% - 0.8rem);
  padding: 0 6px;
  color: #9898a5;
  font-size: 1rem;
  font-weight: 500;
  cursor: text;
  transition: top 0.2s ease-in,
    left 0.2s ease-in,
    font-size 0.2s ease-in;
  background-color: white;

  &.errorState {
    color: #F05050;
    top: -0.6rem;
    font-size: 14px;
    left: 0.5rem;
    padding: 0 6px;
    z-index: 2;
  }
}

.ant-input:focus~.labelnormal,
.ant-input:not(:placeholder-shown).ant-input:not(:focus)~.labelnormal {
  color: #405cd2;
  top: -0.6rem;
  font-size: 14px;
  left: 0.5rem;
  padding: 0 6px;
  z-index: 2;
}

.ant-input:not(:placeholder-shown).ant-input:not(:focus)~.labelnormal {
  color: #5E5F62;

  &.errorShakeEffect {
    color: #F05050 !important;
    animation: shake 0.2s ease-in-out 0s
  }
}

.ant-input-number-handler-wrap {
  display: none !important;
}

.inputdiv .ant-input:not(:placeholder-shown).ant-input:not(:focus):not(.errorState) {
  border: 2px #5E5F62 solid !important;
}

.inputdiv .ant-input:not(:placeholder-shown).ant-input:not(:focus) {
  &.errorState {
    &:hover {
      border-color: #F05050 !important;
    }
  }

  &:hover {
    border-color: #9898a5 !important;
  }
}

.verifyMsg {
  &:not(.errormsg):not(.successmsg) {
    visibility: hidden;
  }

  margin-top:2px;
  display:block;

  &.errormsg {
    color: #F05050;
    margin-top: 2px;
    display: block;
  }

  &.successmsg {
    margin-top: 2px;
    display: block;
    color: green;
  }
}

&.verifying {
  margin-top: 2px;
  display: block;
  color: #405cd2;
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }

  25% {
    margin-left: 0.5rem;
  }

  75% {
    margin-left: -0.5rem;
  }

  100% {
    margin-left: 0rem;
  }
}

label#e6eeff {
  background-color: "#e6eeff";
}

.ant-input:focus~label#e6eeff {
  background-color: "#e6eeff";
}
@primary-color: #405CD2;