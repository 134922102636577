@border-radius-support: 10px;
.phone-holder-style {
  color: @color-Shark;
  font-weight: 600;
  font-size: 17px;
  padding: 15px 2px;
}

.support-container {
  width: 95%;
  border-radius: @border-radius-support;
  margin: 0px auto;
  margin-top: 25px;

  .table-container {
    background-color: @color-White;
    padding: 20px;
    border-radius: @border-radius-support;

    .table-container-heading {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 15px;
    }
  }
}
.form-container {
  background-color: @color-White;
  .display-flex-column-justify-content-center();
  padding: 20px;
  height: auto;
  border-radius: @border-radius-support;

  .form-heading {
    margin-bottom: 15px;
  }
  .form-data-container {
    margin-bottom: 15px;

    .phone-number-holder {
      .phone-country-code {
        .phone-holder-style();
      }
      .phone-number {
        margin-left: 10px;
        .phone-holder-style();
      }
    }
  }

  .form-type {
    display: block;
  }

  .form-labels {
    font-weight: 400;
    font-size: 16px;
    color: @color-ManateeLite;
    display: block;
    margin-bottom: 8px;
  }
}
